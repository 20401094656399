import { AES, enc } from "crypto-js";
const iv = { words: [0, 0, 0, 0], sigBytes: 16 };

function Encrypt(input, key) {
  return AES.encrypt(input, enc.Utf8.parse(key), { iv }).toString();
}

const encryptionStudentsRows = [1, 4, 5, 6, 7, 12];
const encryptionRoomRows = [];
const decryptionStudentKeys = [
  "name",
  "klasse",
  "line",
  "familyGroup",
  "socialGroup",
  "note",
];

function Decrypt(input, key) {
  return AES.decrypt(input, enc.Utf8.parse(key), { iv }).toString(enc.Utf8);
}

export function Capitalize(string) {
  let stringArray = string.split(" ");
  let temp = "";
  for (let i in stringArray) {
    stringArray[i] = stringArray[i].toLowerCase();
    stringArray[i] =
      stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1);

    if (i < stringArray.length - 1) {
      temp += stringArray[i] + " ";
    } else {
      temp += stringArray[i];
    }
  }
  return temp;
}

export function DecryptField(input, key) {
  return Decrypt(input, key);
}

export function DecryptStudent(student, encryptionKey) {
  let temp = {};
  Object.keys(student).forEach((key) => {
    if (decryptionStudentKeys.includes(key)) {
      let decrypted = Decrypt(student[key], encryptionKey);
      temp[key] = Capitalize(decrypted);
    } else {
      temp[key] = student[key];
    }
  });
  return temp;
}

export function DecryptStats(stats, encryptionKey) {
  let statsStringArray = stats.split(":");
  let decryptedName = Decrypt(statsStringArray[0], encryptionKey);
  return Capitalize(decryptedName) + ":" + statsStringArray[1];
}

export function DecryptRoomNotes(notes, encryptionKey) {
  let statsStringArray = notes.split(":");
  let decryptedName = Decrypt(statsStringArray[0], encryptionKey);
  let decryptedNote = Decrypt(
    statsStringArray[1].replace(/\s+/g, ""),
    encryptionKey
  );
  return Capitalize(decryptedName) + " : " + Capitalize(decryptedNote);
}

export function EncryptStudents(students, key) {
  let tempStudents = [];
  for (let i = 1; i < students.length; i++) {
    let currentStudent = students[i];
    tempStudents[i] = [];
    for (let j = 0; j < currentStudent.length; j++) {
      if (encryptionStudentsRows.includes(j)) {
        if (currentStudent[j] === "") {
          tempStudents[i][j] = "";
        } else {
          tempStudents[i][j] = Encrypt(currentStudent[j], key);
        }
      } else {
        tempStudents[i][j] = currentStudent[j];
      }
    }
  }
  return tempStudents;
}

export function EncryptRooms(rooms, key) {
  let tempRooms = [];
  for (let i = 1; i < rooms.length; i++) {
    let currentRoom = rooms[i];
    tempRooms[i] = [];
    for (let j = 0; j < currentRoom.length; j++) {
      if (encryptionRoomRows.includes(j)) {
        if (currentRoom[j] === "") {
          tempRooms[i][j] = "";
        } else {
          tempRooms[i][j] = Encrypt(currentRoom[j], key);
        }
      } else {
        tempRooms[i][j] = currentRoom[j];
      }
    }
  }
  return tempRooms;
}
