import React from "react";
import Card from "react-bootstrap/Card";
import SettingsForm from "./SettingsForm";
import Form from "react-bootstrap/Form";

function SettingsCard(props) {
  return (
    <Card>
      <Card.Header className="text-center">{props.title}</Card.Header>
      <Card.Body>
        <Form>
          <SettingsForm
            settings={props.settings}
            type={props.type}
            handleChange={props.handleChange}
          />
        </Form>
      </Card.Body>
    </Card>
  );
}

export default SettingsCard;
