import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useData } from "../../../../setup/app-context-manager/VrlsDataContext";
import { useSettings } from "../../../../setup/app-context-manager/VrlsSettingsContext";
import { useFirestore } from "../../../../setup/app-context-manager/FirestoreContext";
import { useAuth } from "../../../../setup/app-context-manager/AuthContext";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import FileSaver from "file-saver";

import VrlsSettingsConverter from "../../../../handlers/VrlsSettingsConverter";
import {
  EncryptStudents,
  EncryptRooms,
} from "../../../../handlers/VrlsEncryptData";
import PostAndFetchAxios from "../../../../handlers/VrlsAllocate";
import VrlsExportWorkbook from "../../../../handlers/VrlsExportWorkbook";
import createEncryptionKey from "../../../../handlers/CreateEncryptionKey";

function VrlsFordeling() {
  // Context
  const { students, rooms, clearData, setActiveStep } = useData();
  const { settings } = useSettings();
  const { logLocalRunStatistics } = useFirestore();
  const { getToken } = useAuth();

  // States
  const [serverMessage, setServerMessage] = useState({
    msg: "Hvis I er klar til at lave jeres fordeling, skal I trykke på Fordel-knappen",
    var: "secondary",
    spinner: false,
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function handleReset() {
    // Clears data from VrlsDataContext
    clearData();
    setActiveStep(0);
    navigate("../home");
  }

  async function handleAllocate(e) {
    // Prevents refresh and prevents click on button
    e.preventDefault();
    setLoading(true);

    const key = createEncryptionKey();
    const firebaseToken = await getToken();

    // Gets "raw" and non-decrypted JSON-object from server
    const Response = await PostAndFetchAxios(
      EncryptStudents(students, key),
      EncryptRooms(rooms),
      VrlsSettingsConverter(settings, key),
      firebaseToken,
      setServerMessage,
      setLoading
    );

    if (Response) {
      const exportData = Response[0];
      const exportWorkbook = VrlsExportWorkbook(exportData, key);
      FileSaver.saveAs(exportWorkbook, "Fordeling.xlsx");
      setServerMessage({
        msg: "Din fordeling er blevet overført til din computer - kig i din overførselsmappe",
        var: "success",
        spinner: false,
      });

      const ServerResponses = Response[1];
      let responseString = "";
      for (let i in ServerResponses) {
        responseString += ServerResponses[i].status + ", ";
      }

      // Logs run on local
      logLocalRunStatistics(
        exportData.settings,
        exportData.runStats,
        responseString
      );
    }

    setLoading(false);
  }

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "82vh" }}
    >
      <Container className="text-center mt-3">
        <Row className="justify-content-center">
          <Col></Col>
          <Col xl={6} l={8} xs={8}>
            <h1>Fordeling</h1>
            <Alert variant={serverMessage.var} className="mt-4">
              {serverMessage.spinner ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <></>
              )}{" "}
              {serverMessage.msg}
            </Alert>
            <Form onSubmit={handleAllocate}>
              <Row>
                <Col className="text-start">
                  <Button
                    className="mt-3"
                    variant="outline-secondary"
                    onClick={() => {
                      setActiveStep(4);
                      navigate("../indstillinger");
                    }}
                    disabled={loading}
                  >
                    Tilbage
                  </Button>
                </Col>
                <Col>
                  <Button className="mt-3" disabled={loading} type="submit">
                    Fordel
                  </Button>
                </Col>
                <Col className="text-end">
                  <Button
                    className="mt-3"
                    disabled={loading}
                    onClick={handleReset}
                    variant="outline-danger"
                  >
                    Start forfra
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </Container>
  );
}

export default VrlsFordeling;
