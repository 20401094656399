import { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../../firebase-config";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updateEmail,
  getIdToken,
} from "firebase/auth";

const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUserAuth, setCurrentUserAuth] = useState();
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function updateEmailHandler(email) {
    return updateEmail(currentUserAuth, email);
  }

  function getToken() {
    return getIdToken(currentUserAuth);
  }

  // Hook, that controls user authentication
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        setCurrentUserAuth(userAuth);
        setLoading(false);
      } else {
        setCurrentUserAuth();
        setLoading(false);
      }
    });
    return unsubscribeAuth;
  }, []);

  const value = {
    currentUserAuth,
    loading,
    getToken,
    signup,
    login,
    logout,
    resetPassword,
    updateEmailHandler,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
