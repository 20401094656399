import React from "react";
import Modal from "react-bootstrap/Modal";
import AdvIndstillinger from "./AdvIndstillinger";
import Button from "react-bootstrap/Button";

function ModalAdvIndstillinger(props) {
  return (
    <Modal
      show={props.showAdvanced}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header>
        <Modal.Title>Avancerede indstillinger</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AdvIndstillinger
          settings={props.settings}
          handleChange={props.handleChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={() => {
            props.handleResetAdvanced();
          }}
        >
          Nulstil
        </Button>
        <Button variant="primary" onClick={props.handleAdvancedClose}>
          Gem og luk
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAdvIndstillinger;
