import React from "react";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../../../setup/app-context-manager/FirestoreContext";
import { useSettings } from "../../../setup/app-context-manager/VrlsSettingsContext";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";

function TemplateList() {
  const { currentUserTemplates, deleteSettingsTemplate } = useFirestore();
  const { applyTemplate } = useSettings();
  let navigate = useNavigate();

  function handleUseTemplate(template) {
    applyTemplate(template);
    navigate("../opret-skabelon");
  }

  if (currentUserTemplates !== null && currentUserTemplates !== undefined) {
    return (
      <ListGroup>
        {currentUserTemplates.map((element) => {
          return (
            <ListGroup.Item key={element.name}>
              <Row>
                <Col sm={6} lg={8} className="d-flex align-items-center">
                  {element.name}
                </Col>

                <Col className="d-flex align-items-center">
                  <Button
                    onClick={() => {
                      handleUseTemplate(element);
                    }}
                  >
                    <AiOutlineEdit />
                  </Button>
                </Col>
                <Col className="d-flex align-items-center">
                  <Button
                    onClick={() => {
                      deleteSettingsTemplate(element.id);
                    }}
                    variant="danger"
                  >
                    <AiOutlineDelete />
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }
}

export default TemplateList;
