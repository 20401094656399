import React from "react";
import { useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ModalAdvIndstillinger from "../vrls/indstillinger/ModalAdvIndstillinger";
import SettingsCard from "../vrls/indstillinger/SettingsCard";
import { useSettings } from "../../../setup/app-context-manager/VrlsSettingsContext";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useFirestore } from "../../../setup/app-context-manager/FirestoreContext";

function CreateTemplate() {
  const {
    settings,
    handleChange,
    handleReset,
    resetSettings,
    handleResetAdvanced,
    currentTemplate,
    resetCurrentTemplate,
  } = useSettings();

  const { setSettingsTemplate } = useFirestore();
  let navigate = useNavigate();

  // React Hooks
  const templateNameRef = useRef();
  const [errorMsg, setErrorMsg] = useState("");

  // Logik for visning af avancerede indstillinger modal
  const [ModalAdvanced, setModalAdvanced] = useState(false);

  const handleAdvancedClose = () => setModalAdvanced(false);
  const handleAdvancedShow = () => setModalAdvanced(true);

  async function handleSubmit() {
    let templateName = templateNameRef.current.value;
    if (templateName !== "") {
      if (currentTemplate.length === 0) {
        await setSettingsTemplate(templateName, settings);
        resetSettings();
        resetCurrentTemplate();
        navigate("../profil");
      } else {
        await setSettingsTemplate(templateName, settings, currentTemplate[1]);
        resetSettings();
        resetCurrentTemplate();
        navigate("../profil");
      }
    } else {
      setErrorMsg("Husk at skrive et skabelonnavn");
    }
  }

  function handleBack() {
    resetSettings();
    navigate("../profil");
  }

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "88vh" }}
      >
        <Container className="my-3 text-center">
          <Row>
            <Col
              xs={4}
              className="d-flex align-items-center justify-content-center"
            >
              <Button variant="danger" onClick={handleReset}>
                Nulstil Indstillinger
              </Button>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center justify-content-center"
            >
              {currentTemplate.length === 0 ? (
                <h3>Opret ny skabelon</h3>
              ) : (
                <h3>Rediger skabelon</h3>
              )}
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center justify-content-center"
            ></Col>
          </Row>
          <Row className="my-3">
            <Form className="text-start">
              <Form.Group as={Row}>
                <Form.Label column sm="2">
                  Skabelonnavn
                </Form.Label>
                <Col sm="5">
                  {currentTemplate.length === 0 ? (
                    <Form.Control
                      type="text"
                      placeholder="Skabelonnavn"
                      ref={templateNameRef}
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      placeholder="Skabelonnavn"
                      defaultValue={currentTemplate[0]}
                      ref={templateNameRef}
                    />
                  )}
                </Col>
              </Form.Group>
            </Form>
            {errorMsg && (
              <Alert className="mt-4" variant="danger">
                {errorMsg}
              </Alert>
            )}
          </Row>
          <Row className=" my-3">
            <Col xs={4} className="text-start">
              <SettingsCard
                title="Værelsesindstillinger"
                type="Værelse"
                settings={settings}
                handleChange={handleChange}
              />
            </Col>
            <Col xs={4} className="text-start">
              <SettingsCard
                title="Gangindstillinger"
                type="Gang"
                settings={settings}
                handleChange={handleChange}
              />
            </Col>
            <Col xs={4} className="text-start">
              <SettingsCard
                title="Bytindstillinger"
                type="Værelsesbyt"
                settings={settings}
                handleChange={handleChange}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={4}>
              <Button onClick={handleBack} variant="outline-dark">
                Tilbage
              </Button>
            </Col>
            <Col xs={4}>
              <Button variant="info" onClick={handleAdvancedShow}>
                Avancerede Indstillinger
              </Button>
            </Col>
            <Col xs={4}>
              <Button onClick={handleSubmit}>
                {currentTemplate.length === 0
                  ? "Opret skabelon"
                  : "Gem skabelon"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <ModalAdvIndstillinger
        showAdvanced={ModalAdvanced}
        handleAdvancedClose={handleAdvancedClose}
        handleResetAdvanced={handleResetAdvanced}
        settings={settings}
        handleChange={handleChange}
      />
    </>
  );
}

export default CreateTemplate;
