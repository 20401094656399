import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useAuth } from "../setup/app-context-manager/AuthContext";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function NavbarGlobal() {
  const { currentUserAuth, logout } = useAuth();
  let navigate = useNavigate();

  async function handleLogout() {
    try {
      await logout();
      navigate("../../login");
    } catch {
      console.log("Kunne ikke logge ud");
    }
  }

  return (
    <Navbar
      bg="dark"
      variant="dark"
      style={{ minHeight: "3rem", maxHeight: "6vh" }}
    >
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <a href="https://vrls.dk" className="mx-3">
          <Navbar.Brand>
            <img
              width="100"
              height="30"
              src="/logo_alpha.png"
              className="d-inline-block align-top"
              alt="VRLS"
            />
          </Navbar.Brand>
        </a>
        <Nav className="me-auto">
          {currentUserAuth && (
            <>
              <LinkContainer to="/dashboard/vrls/home" className="mx-3">
                <Nav.Link>VRLS</Nav.Link>
              </LinkContainer>
            </>
          )}
        </Nav>
        <Nav>
          {!currentUserAuth ? (
            <>
              <LinkContainer to="/login" className="mx-3">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/opret" className="mx-3">
                <Button style={{ borderradius: 10 }}>Opret Bruger</Button>
              </LinkContainer>
            </>
          ) : (
            <>
              <Container>
                <Button
                  className="mx-3"
                  onClick={() => {
                    navigate("/dashboard/profil");
                  }}
                >
                  Profil
                </Button>
                <Button variant="secondary" onClick={handleLogout}>
                  Log ud
                </Button>
              </Container>
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}

export default NavbarGlobal;
