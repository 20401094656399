function VrlsCheckImport(students, rooms) {
  // Creates an object containing all import stats
  let importStats = {
    students: 0,
    boys: 0,
    girls: 0,
    klasser: [],
    klasserCount: 0,
    lines: [],
    linesCount: 0,
    familyGroups: [],
    familyGroupsCount: 0,
    socialGroups: [],
    socialGroupsCount: 0,
    vuln: [],
    vulnCount: 0,
    secondYear: [],
    secondYearCount: 0,
    boysStudentNumbers: [],
    girlStudentNumbers: [],
    studentNumbers: [],
    prevRoomNames: [],

    rooms: 0,
    boyBeds: 0,
    girlBeds: 0,
    beds: 0,
    prePlacedBoys: [],
    prePlacedBoysCount: 0,
    prePlacedGirls: [],
    prePlacedGirlsCount: 0,
    halls: [],
    hallsCount: 0,
    roomNames: [],

    wronglyPlacedBoys: [],
    wronglyPlacedBoysCount: 0,
    wronglyPlacedGirls: [],
    wronglyPlacedGirlsCount: 0,

    wrongStudentGender: [], // Elevnumre på elever med forkert køn
    wrongRoomGender: [], // Værelsesnavne på værelser med forkert køn
    wrongPostalCode: [], // Elevnumre på elever med forkert postnummer

    duplicateStudentNumbers: [],
    nameOfWrongStudentNumbers: [],
    nameOfWrongRoomSize: [],
    duplicateRoomNames: [],
    MissingPrevRooms: [], // Navne på prev rooms, der ikke er i værelseslisten

    err: [],
    errCount: 0,
  };

  // Fills importstats with student data
  for (let i = 1; i < students.length; i++) {
    let currentStudent = students[i];

    // Checks if the studentnumbers are numbers
    if (/^\d+$/.test(currentStudent[0]) === false) {
      importStats.nameOfWrongStudentNumbers.push(currentStudent[1]);
    }

    // Checks if the postalcodes are numbers
    if (currentStudent[3] !== "") {
      if (/^\d+$/.test(currentStudent[3]) === false) {
        importStats.wrongPostalCode.push(currentStudent[0]);
      }
    }

    switch (currentStudent[2]) {
      case "M":
        importStats.boys++;
        importStats.boysStudentNumbers.push(currentStudent[0]);
        break;

      case "K":
        importStats.girls++;
        importStats.girlStudentNumbers.push(currentStudent[0]);
        break;

      default:
        importStats.wrongStudentGender.push(currentStudent[0]);
        break;
    }

    if (currentStudent[5] != null) {
      if (!importStats.klasser[currentStudent[5]]) {
        importStats.klasser[currentStudent[5]] = 0;
      }
      importStats.klasser[currentStudent[5]] += 1;
    }

    if (currentStudent[4] != null) {
      if (!importStats.lines[currentStudent[4]]) {
        importStats.lines[currentStudent[4]] = 0;
      }
      importStats.lines[currentStudent[4]] += 1;
    }

    if (currentStudent[6] != null) {
      if (!importStats.familyGroups[currentStudent[6]]) {
        importStats.familyGroups[currentStudent[6]] = 0;
      }
      importStats.familyGroups[currentStudent[6]] += 1;
    }

    // Store og små bogstaver
    if (currentStudent[7] != null) {
      if (!importStats.socialGroups[currentStudent[7]]) {
        importStats.socialGroups[currentStudent[7]] = 0;
      }
      importStats.socialGroups[currentStudent[7]] += 1;
    }

    // Laver liste med tidligere værelsesnavne
    if (currentStudent[8] != null) {
      let tempArray = currentStudent[8].split(",");
      for (let j = 0; j < tempArray.length; j++) {
        let tempRoom = tempArray[j].trim();
        if (!importStats.prevRoomNames.includes(tempRoom) && tempRoom != "") {
          importStats.prevRoomNames.push(tempRoom);
        }
      }
    }

    if (["x", "X"].includes(currentStudent[9])) {
      importStats.vuln.push(currentStudent[0]);
    }
    if (["x", "X"].includes(currentStudent[10])) {
      importStats.secondYear.push(currentStudent[0]);
    }
  }

  // Fills importstats with room data
  for (let i = 1; i < rooms.length; i++) {
    let currentRoom = rooms[i];

    // Checks if the studentnumbers are numbers
    if (/^\d+$/.test(currentRoom[1]) === false) {
      importStats.nameOfWrongRoomSize.push(currentRoom[0]);
    }

    importStats.roomNames.push(currentRoom[0].trim());

    switch (currentRoom[2]) {
      case "M":
        importStats.boyBeds += +currentRoom[1];
        importStats.rooms += 1;
        // Adds to preplaced numbers
        if (
          (String(currentRoom[4]).trim() !== "") &
          (currentRoom[4] !== undefined)
        ) {
          let prePlaArr;
          if (typeof currentRoom[4] === "number") {
            prePlaArr = String(currentRoom[4]).split(".");
          } else if (typeof currentRoom[4] === "string") {
            prePlaArr = currentRoom[4].split(",");
          }
          for (let j = 0; j < prePlaArr.length; j++) {
            let studNumb = prePlaArr[j];
            importStats.prePlacedBoys.push([studNumb.trim(), currentRoom[0]]);
          }
        }
        break;

      case "K":
        importStats.girlBeds += +currentRoom[1];
        importStats.rooms += 1;
        // Adds to preplaced numbers
        if (
          (String(currentRoom[4]).trim() !== "") &
          (currentRoom[4] !== undefined)
        ) {
          let prePlaArr;
          if (typeof currentRoom[4] === "number") {
            prePlaArr = String(currentRoom[4]).split(".");
          } else if (typeof currentRoom[4] === "string") {
            prePlaArr = currentRoom[4].split(",");
          }
          for (let j = 0; j < prePlaArr.length; j++) {
            let studNumb = prePlaArr[j];
            importStats.prePlacedGirls.push([studNumb.trim(), currentRoom[0]]);
          }
        }
        break;

      default:
        importStats.wrongRoomGender.push(currentRoom[0]);
        break;
    }
    // Adds halls
    if (currentRoom[3] != null) {
      if (!importStats.halls[currentRoom[3]]) {
        importStats.halls[currentRoom[3]] = 0;
      }
      importStats.halls[currentRoom[3]] += 1;
    }
  }

  // Checks if preplacedboys are actually boys
  for (let i = 0; i < importStats.prePlacedBoys.length; i++) {
    let boy = importStats.prePlacedBoys[i];
    if (importStats.boysStudentNumbers.includes(boy[0]) === false) {
      importStats.wronglyPlacedGirls.push(boy);
    }
  }
  // Checks if preplacedgirls are actually girls
  for (let i = 0; i < importStats.prePlacedGirls.length; i++) {
    let girl = importStats.prePlacedGirls[i];
    if (importStats.girlStudentNumbers.includes(girl[0]) === false) {
      importStats.wronglyPlacedBoys.push(girl);
    }
  }

  // Collects all student numbers in one array
  for (let i in importStats.boysStudentNumbers) {
    importStats.studentNumbers.push(importStats.boysStudentNumbers[i]);
  }
  for (let i in importStats.girlStudentNumbers) {
    importStats.studentNumbers.push(importStats.girlStudentNumbers[i]);
  }

  // Checks if there are duplicate student numbers
  const findDuplicates = (arr) =>
    arr.filter((item, index) => arr.indexOf(item) !== index);

  importStats.duplicateStudentNumbers = findDuplicates(
    importStats.studentNumbers
  );

  // Checks if there are duplicate room names
  importStats.duplicateRoomNames = findDuplicates(importStats.roomNames);

  // Checks if there are prevRooms not in the room list
  for (let j = 0; j < importStats.prevRoomNames.length; j++) {
    if (!importStats.roomNames.includes(importStats.prevRoomNames[j])) {
      importStats.MissingPrevRooms.push(importStats.prevRoomNames[j]);
    }
  }

  // Final counting
  importStats.students = importStats.boys + importStats.girls;
  importStats.beds = importStats.boyBeds + importStats.girlBeds;
  importStats.klasserCount = Object.keys(importStats.klasser).length;
  importStats.linesCount = Object.keys(importStats.lines).length;
  importStats.familyGroupsCount = Object.keys(importStats.familyGroups).length;
  importStats.socialGroupsCount = Object.keys(importStats.socialGroups).length;
  importStats.wronglyPlacedBoysCount = importStats.wronglyPlacedBoys.length;
  importStats.wronglyPlacedGirlsCount = importStats.wronglyPlacedGirls.length;
  importStats.hallsCount = Object.keys(importStats.halls).length;
  importStats.prePlacedBoysCount = importStats.prePlacedBoys.length;
  importStats.prePlacedGirlsCount = importStats.prePlacedGirls.length;
  importStats.vulnCount = importStats.vuln.length;
  importStats.secondYearCount = importStats.secondYear.length;

  // Object.keys(importStats.klasser).sort();
  // Object.keys(importStats.lines).sort();
  // Object.keys(importStats.familyGroups).sort();
  // Object.keys(importStats.socialGroups).sort();

  return importStats;
}

export default VrlsCheckImport;
