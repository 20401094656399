import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function NavbarVrls() {
  return (
    <Navbar
      bg="white"
      variant="white"
      style={{ minHeight: "3rem", maxHeight: "6vh" }}
    >
      <Container className="justify-content-center">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Nav className="">
          <LinkContainer to="/dashboard/vrls/home" className="mx-3">
            <Nav.Link>Startside</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/dashboard/vrls/elevimport" className="mx-3">
            <Nav.Link>Importer elever</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/dashboard/vrls/vaerelseimport" className="mx-3">
            <Nav.Link>Importer værelser</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/dashboard/vrls/godkend" className="mx-3">
            <Nav.Link>Godkend import</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/dashboard/vrls/indstillinger" className="mx-3">
            <Nav.Link>Indstillinger</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/dashboard/vrls/fordeling" className="mx-3">
            <Nav.Link>Fordeling</Nav.Link>
          </LinkContainer>
        </Nav>
      </Container>
    </Navbar>
  );
}

export default NavbarVrls;
