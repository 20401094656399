import ListGroup from "react-bootstrap/ListGroup";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";

function ItemList(props) {
  if (
    props.group &&
    !(
      typeof props.group[0] === "string" || typeof props.group[0] === "number"
    ) &&
    props.value !== 0
  ) {
    // List item for Objekter med Modal
    return (
      <ListGroup.Item
        action
        onClick={() => {
          props.handleInfoShow(
            props.name,
            props.group,
            props.groupname,
            props.modaltype
          );
        }}
      >
        <Row>
          <Col className="text-start" xs={7}>
            {props.name}:
          </Col>
          <Col className="text-center" xs={5}>
            <Badge bg={props.value === 0 ? "secondary" : "primary"}>
              {props.value}
            </Badge>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  } else if (
    props.group &&
    props.group.length > 0 &&
    (typeof props.group[0] === "string" || typeof props.group[0] === "number")
  ) {
    return (
      <OverlayTrigger
        key={`overlay-${props.name}`}
        placement={"right"}
        overlay={
          <Popover id={`popover-${props.name}`}>
            <Popover.Header>{props.name}</Popover.Header>
            <Popover.Body>
              {props.group.map((elem) => {
                return String(elem) + ", ";
              })}
            </Popover.Body>
          </Popover>
        }
      >
        <ListGroup.Item
          action
          onClick={() => {
            console.log(props.value);
          }}
        >
          <Row>
            <Col className="text-start" xs={7}>
              {props.name}:
            </Col>
            <Col className="text-center" xs={5}>
              <Badge bg={props.value === 0 ? "secondary" : "primary"}>
                {props.value}
              </Badge>
            </Col>
          </Row>
        </ListGroup.Item>
      </OverlayTrigger>
    );
  } else {
    return (
      <ListGroup.Item>
        <Row>
          <Col className="text-start" xs={7}>
            {props.name}:
          </Col>
          <Col className="text-center" xs={5}>
            <Badge bg={props.value === 0 ? "secondary" : "primary"}>
              {props.value}
            </Badge>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }
}

export default ItemList;
