import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import ProfileForm from "./ProfileForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TemplateCard from "./TemplateCard";

function Profile() {
  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "88vh" }}
    >
      <Container className="mt-4">
        <Row>
          <Col className="">
            <Card>
              <h2 className="mt-3 text-center">Brugerprofil</h2>
              <Card.Body>
                <ProfileForm />
              </Card.Body>
              <Card.Body className="text-center">
                <Link to="/dashboard/opdater-password">
                  Opdater adgangskode
                </Link>
              </Card.Body>
              <Card.Footer className="text-center">
                <Link to="/dashboard/opdater-profil">Opdater oplysninger</Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <TemplateCard />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Profile;
