import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useState } from "react";
import { useAuth } from "../../setup/app-context-manager/AuthContext";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../../setup/app-context-manager/FirestoreContext";

function LoginForm(props) {
  const { signup } = useAuth();
  const { createUser } = useFirestore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  let navigate = useNavigate();

  async function handleSignUp(e) {
    e.preventDefault();

    if (
      props.passwordRef.current.value !== props.confirmpasswordRef.current.value
    ) {
      setError("Adgangskoderne matcher ikke");
      return;
    }

    try {
      setError("");
      setLoading(true);
      return await signup(
        props.mailRef.current.value,
        props.passwordRef.current.value
      )
        .then((userCred) => {
          return createUser(
            userCred.user.uid,
            props.nameRef.current.value,
            props.numberRef.current.value,
            props.schoolRef.current.value
          );
        })
        .then(() => {
          navigate("../dashboard/home");
        });
    } catch (error) {
      if (error.code === "auth/weak-password") {
        setError("Adgangskoden skal have indeholde mindst 6 karakterer");
      } else if (error.code === "auth/invalid-email") {
        setError("Tjek emailadressen");
      } else if (error.code === "auth/email-already-in-use") {
        setError("Der er allerede oprettet en bruger med den mail");
      } else {
        setError(
          "Vi kunne desværre ikke oprette din bruger. Kontakt os for yderligere hjælp."
        );
        console.log(error.code);
      }
    }

    setLoading(false);
  }

  return (
    <Form onSubmit={handleSignUp}>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Navn</Form.Label>
        <Form.Control
          type="text"
          ref={props.nameRef}
          placeholder="Navn"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicNumber">
        <Form.Label>Telefonnummer</Form.Label>
        <Form.Control
          type="tel"
          ref={props.numberRef}
          placeholder="21562643"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicSchool">
        <Form.Label>Skole</Form.Label>
        <Form.Control
          type="text"
          ref={props.schoolRef}
          placeholder="Skole"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          ref={props.mailRef}
          placeholder="Email"
          required
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Adgangskode</Form.Label>
        <Form.Control
          type="password"
          placeholder="Adgangskode"
          ref={props.passwordRef}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
        <Form.Label>Bekræft Adgangskode</Form.Label>
        <Form.Control
          type="password"
          placeholder="Bekræft Adgangskode"
          ref={props.confirmpasswordRef}
          required
        />
      </Form.Group>
      <Button
        disabled={loading}
        variant="primary"
        type="submit"
        className="w-100"
      >
        Opret bruger
      </Button>
    </Form>
  );
}

export default LoginForm;
