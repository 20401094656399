import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useState } from "react";
import { useAuth } from "../../../setup/app-context-manager/AuthContext";
import { useNavigate } from "react-router-dom";
import { useFirestore } from "../../../setup/app-context-manager/FirestoreContext";

function UpdateProfileForm(props) {
  const { currentUserAuth, updateEmailHandler } = useAuth();
  const { currentUserData, updateUserDoc } = useFirestore();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  let navigate = useNavigate();

  function handleUpdate(e) {
    e.preventDefault();

    const promises = [];
    setLoading(true);
    setError("");

    // STUPID SHIT
    const newName = props.nameRef.current.value;
    const newSchool = props.schoolRef.current.value;
    const newEmail = props.emailRef.current.value;
    const newNumber = props.numberRef.current.value;

    if (newEmail !== currentUserAuth.email) {
      console.log("opdatere mail");
      promises.push(updateEmailHandler(newEmail));
    }
    if (
      newName !== currentUserData?.name ||
      newSchool !== currentUserData?.school ||
      newNumber !== currentUserData?.number
    ) {
      const userData = {
        name: newName,
        school: newSchool,
        number: newNumber,
      };
      promises.push(updateUserDoc(userData));
    }
    Promise.all(promises)
      .then(() => {
        navigate("../profil");
      })
      .catch((err) => {
        setError("Kunne ikke opdatere bruger");
        console.log(err.code);
      });

    setLoading(false);
  }

  return (
    <Form onSubmit={handleUpdate}>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Navn</Form.Label>
        <Form.Control
          type="text"
          ref={props.nameRef}
          placeholder="Navn"
          defaultValue={currentUserData?.name}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicSchool">
        <Form.Label>Skole</Form.Label>
        <Form.Control
          type="text"
          ref={props.schoolRef}
          placeholder="Skole"
          defaultValue={currentUserData?.school}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          ref={props.emailRef}
          placeholder="Email"
          defaultValue={currentUserAuth?.email}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicNumber">
        <Form.Label>Telefon</Form.Label>
        <Form.Control
          type="tel"
          ref={props.numberRef}
          placeholder="Telefonnummer"
          defaultValue={currentUserData?.number}
        />
      </Form.Group>

      <Button
        disabled={loading}
        variant="primary"
        type="submit"
        className="w-100"
      >
        Opdater
      </Button>
    </Form>
  );
}

export default UpdateProfileForm;
