import { IndstillingerList } from "../assets/Indstillinger";

function VrlsSettingsInverter(settingsObject) {
  // Imports defaultSettings from the SettingsList
  let settings = IndstillingerList("Alt");

  // Divides the settingsObject into the two subparts: checkboxes and advanced
  let checkboxes = settingsObject.checkboxes;
  let advanced = settingsObject.advanced;

  // Defines details for disabled-advanced settings
  let useWeight = false;
  let advancedDefaultDisabledSettings = [
    "brugVægt",
    "klasseVægt",
    "linjeVægt",
    "gruppeVægt",
    "andenårsVægt",
    "sårbarVægt",
    "landsdelVægt",
    "brugerdefVægt",
    "tidlGangOpdelVægt",
  ];
  let advancedGenericWeight = "hallIncPercent";

  // Inverter Logik
  Object.keys(checkboxes).forEach((key) => {
    settings.map((settingsElement) => {
      if (String(key) === String(settingsElement.serverName)) {
        let tempValue = checkboxes[key];
        return (settingsElement.value = tempValue);
      }
    });
  });

  // Inverter Logik
  Object.keys(advanced).forEach((key) => {
    settings.map((settingsElement) => {
      if (String(key) === String(settingsElement.serverName)) {
        let tempValue = advanced[key];
        if (String(key) === "brugVægt") {
          useWeight = Boolean(advanced[key]);
          return (settingsElement.value = tempValue);
        } else {
          return (settingsElement.value = tempValue);
        }
      }
    });
  });

  // Makes sure, that disabled are correct for the advanced settings
  if (useWeight === true) {
    Object.keys(advanced).forEach((key) => {
      settings.map((settingsElement) => {
        if (String(key) === String(settingsElement.serverName)) {
          if (advancedDefaultDisabledSettings.includes(String(key))) {
            return (settingsElement.disabled = false);
          } else if (String(key) === advancedGenericWeight) {
            return (settingsElement.disabled = true);
          }
        }
      });
    });
  }

  return settings;
}

export default VrlsSettingsInverter;
