import React from "react";

function Footer() {
  return (
    <div
      className="text-center p-3"
      style={{
        minHeight: "3rem",
        maxHeight: "6vh",
        backgroundColor: "rgba(0, 0, 0, 0.03)",
      }}
    >
      Version 1.1.0 (Latest: 27-01-2023) © 2023 Copyright: VRLS
    </div>
  );
}

export default Footer;
