import React from "react";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useData } from "../../../../setup/app-context-manager/VrlsDataContext";

function ErrorAlert(props) {
  const { importComplete } = useData();
  if (!importComplete) {
    return <Alert variant="danger">Vi har ikke læst din fil</Alert>;
  } else if (props.errors.length === 0) {
    return (
      <Alert variant="success">
        Vi har ikke fundet nogle fejl i din import
      </Alert>
    );
  } else {
    return (
      <Row>
        {props.errors.map((errMsg) => {
          return (
            <Col lg={true} key={`Col-${errMsg[0]}`}>
              <Alert
                className="is--pointer"
                variant="danger"
                key={`Alert-${errMsg[0]}`}
                onClick={() => {
                  props.handleErrorShow(errMsg);
                }}
              >
                {errMsg[0]}
              </Alert>
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default ErrorAlert;
