import React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useAuth } from "../../../setup/app-context-manager/AuthContext";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

function ResetPassword() {
  const { resetPassword, currentUserAuth } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  async function handleReset(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(currentUserAuth.email);
      setMessage("Check din mail for yderligere instruktioner");
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setError("Ikke gyldig email");
      } else if (error.code === "auth/missing-email") {
        setError("Indtast email");
      } else {
        setError("Kunne ikke nulstille adgangskode");
        console.log(error.code);
      }
    }

    setLoading(false);
  }

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "88vh" }}
    >
      <div className="w-100" style={{ maxWidth: "500px" }}>
        <Card>
          <h2 className="mt-3 text-center">Opdater adgangskode</h2>
          <Card.Body>
            <Form onSubmit={handleReset}>
              {error && <Alert variant="danger">{error}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}
              <p className="text-center">
                Er du sikker på at du vil opdatere din adgangskode?
              </p>
              <Button
                disabled={loading}
                variant="primary"
                type="submit"
                className="w-100"
              >
                Opdater adgangskode
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer className="text-center">
            <Link to="/dashboard/profil">Fortryd</Link>
          </Card.Footer>
        </Card>
      </div>
    </Container>
  );
}

export default ResetPassword;
