import * as XLSX from "xlsx";
import {
  DecryptStudent,
  DecryptField,
  DecryptStats,
  DecryptRoomNotes,
  Capitalize,
} from "./VrlsEncryptData";

export default function VrlsExportWorkbook(data, encryptionKey) {
  var workbook = XLSX.utils.book_new();

  // Gets different objects from the full import
  let studentDataArray = Object.values(data.studentData);
  let roomDataArray = Object.values(data.roomData);
  let hallDataArray = Object.values(data.hallData);
  let settingsArray = Object.values(data.settings);
  let runStatsArray = Object.values(data.runStats);

  // Initiates resultdata sheet
  let resultData = [
    [
      "Elevnr",
      "Navn",
      "Køn",
      "Gang 1",
      "Værelse 1",
      "Gang 2",
      "Værelse 2",
      "Gang 3",
      "Værelse 3",
      "Postnr",
      "Linje",
      "Klasse",
      "Gruppe",
      "Brugerdefineret Gruppe",
      "Sårbar",
      "Andetårs",
      "Undgå elever",
      "Undgå værelser",
      "Note",
    ],
  ];

  // Fills sheet with data concerning rooms
  for (let i in studentDataArray) {
    let student = studentDataArray[i];
    // Decrypts data
    student = DecryptStudent(student, encryptionKey);

    let vuln = "";
    let secondYear = "";
    let hall1 = "";
    let hall2 = "";
    let hall3 = "";
    let room1 = "";
    let room2 = "";
    let room3 = "";
    let redListString = "";
    let avoidRoomString = "";

    // Sets visual representation of vuln and secondyear
    if (student.isVulnerable) {
      vuln = "X";
    }
    if (student.isSecondYear) {
      secondYear = "X";
    }

    // Creates correct string of prev rooms
    if (student.prevRooms.length >= 2) {
      room3 = student.room;
      room2 = student.prevRooms[1];
      room1 = student.prevRooms[0];
    } else if (student.prevRooms.length === 1) {
      room2 = student.room;
      room1 = student.prevRooms[0];
    } else {
      room1 = student.room;
    }

    // Creates correct string of prev rooms
    if (student.prevHalls.length >= 2) {
      hall3 = student.hall;
      hall2 = student.prevHalls[1];
      hall1 = student.prevHalls[0];
    } else if (student.prevHalls.length === 1) {
      hall2 = student.hall;
      hall1 = student.prevHalls[0];
    } else {
      hall1 = student.hall;
    }

    if (student.avoidRooms !== []) {
      for (let j in student.avoidRooms) {
        let roomName = student.avoidRooms[j];
        avoidRoomString += roomName + ",";
      }
      avoidRoomString = avoidRoomString.slice(0, -1);
    }

    // Creates correct string for redlist
    if (student.redList !== []) {
      for (let j in student.redList) {
        let num = student.redList[j];
        redListString += num + ",";
      }
      redListString = redListString.slice(0, -1);
    }

    // Puts data from student to next excel line
    resultData.push([
      student.elevnr,
      student.name,
      student.gender,
      hall1,
      room1,
      hall2,
      room2,
      hall3,
      room3,
      student.postnr,
      student.line,
      student.klasse,
      student.familyGroup,
      student.socialGroup,
      vuln,
      secondYear,
      redListString,
      avoidRoomString,
      student.note,
    ]);
  }

  // Fills top row in switch page
  let studentData = [
    [
      "Elevnr",
      "Navn",
      "Køn",
      "Postnr",
      "Linje",
      "Klasse",
      "Gruppe",
      "Brugerdefineret Gruppe",
      "Tidligere Værelser",
      "Sårbar",
      "Andetårs",
      "Undgå elever:",
      "Note",
    ],
  ];

  // Fills student data in switch page
  for (let i in studentDataArray) {
    let student = studentDataArray[i];
    // Decrypts data
    student = DecryptStudent(student, encryptionKey);

    let vuln = "";
    let secondYear = "";
    let prevRoomString = "";
    let redListString = "";

    // Sets visual representation of vuln and secondyear
    if (student.isVulnerable) {
      vuln = "X";
    }
    if (student.isSecondYear) {
      secondYear = "X";
    }

    // Creates correct string of prev rooms
    for (let j in student.prevRooms) {
      let rom = student.prevRooms[j];
      prevRoomString += rom + ",";
    }
    prevRoomString += student.room;

    if (prevRoomString[0] === ",") {
      prevRoomString = prevRoomString.substring(1);
    }

    // Creates correct string for redlist
    if (student.redList !== []) {
      for (let j in student.redList) {
        let num = student.redList[j];
        redListString += num + ",";
      }
      redListString = redListString.slice(0, -1);
    }

    // Puts data from student to next excel line
    studentData.push([
      student.elevnr,
      student.name,
      student.gender,
      student.postnr,
      student.line,
      student.klasse,
      student.familyGroup,
      student.socialGroup,
      prevRoomString,
      vuln,
      secondYear,
      redListString,
      student.note,
    ]);
  }

  // Initiates stats page array
  let stats = [
    ["Kørselsstatistik: "],
    ["Værelseskombinationer forsøgt: " + runStatsArray[1]],
    ["Gang størrelse til bløde funktioner forøget med: " + runStatsArray[0]],
    ["Værelsesindstillinger brugt: " + settingsArray[2].room],
    ["Ganginstillinger: " + settingsArray[2].hall],
    ["Værelsesbyt indstillinger: " + settingsArray[2].switch],
    [],
  ];

  // Finds indexes where font must be headerfont in stats xlsx file
  let hallHeaderIndexArray = [8];

  // Fills array with stats
  for (let i in hallDataArray) {
    let hall = hallDataArray[i];

    let hallStatsArray = [];

    hallStatsArray.push([
      "Gang",
      "Antal fra klasse",
      "Antal fra linje",
      "Antal fra landsdel",
      "Antal fra gruppe",
      "Antal fra brugerdef. gruppe",
      "Elev noter",
      "",
    ]);

    hallStatsArray.push(
      ["Navn: " + Capitalize(hall.name), "", "", "", "", "", ""],
      ["Størrelse: " + hall.size, "", "", "", "", "", ""],
      ["Drenge: " + hall.boys, "", "", "", "", "", ""],
      ["Piger: " + hall.girls, "", "", "", "", "", ""],
      ["Sårbare: " + hall.vuln, "", "", "", "", "", ""],
      ["Andetårs: " + hall.secondYear, "", "", "", "", "", ""]
    );

    let arrayLength =
      +Math.max(
        hall.klasser.length,
        hall.lines.length,
        hall.familyGroups.length,
        hall.socialGroups.length,
        hall.notes.length,
        hall.landsdele.length
      ) - +5;

    hallHeaderIndexArray.push(
      hallHeaderIndexArray[+hallHeaderIndexArray.length - 1] + +arrayLength + +7
    );

    for (let j = 0; j < arrayLength; j++) {
      hallStatsArray.push(["", "", "", "", "", "", "", "", ""]);
    }

    // Fills klasse stats
    for (let j in hall.klasser) {
      let klasse = DecryptStats(hall.klasser[j], encryptionKey);
      hallStatsArray[+j + +1][1] = klasse;
    }

    // Fills line stats
    for (let j in hall.lines) {
      let line = DecryptStats(hall.lines[j], encryptionKey);
      hallStatsArray[+j + +1][2] = line;
    }

    // Fills familyGroup stats
    for (let j in hall.familyGroups) {
      let familyGroup = DecryptStats(hall.familyGroups[j], encryptionKey);
      hallStatsArray[+j + +1][4] = familyGroup;
    }

    // Fills socialGroup stats
    for (let j in hall.socialGroups) {
      let socialGroup = DecryptStats(hall.socialGroups[j], encryptionKey);
      hallStatsArray[+j + +1][5] = socialGroup;
    }

    // Fills landsdel stats
    for (let j in hall.landsdele) {
      let landsdel = hall.landsdele[j];
      hallStatsArray[+j + +1][3] = landsdel;
    }

    // Fills notes
    for (let j in hall.notes) {
      let note = DecryptRoomNotes(hall.notes[j], encryptionKey);
      hallStatsArray[+j + +1][6] = note;
    }

    stats = stats.concat(hallStatsArray);
  }

  // Turns arrays with all sheetinfo into worksheets
  let worksheetResult = XLSX.utils.aoa_to_sheet(resultData);
  let worksheetForSwitch = XLSX.utils.aoa_to_sheet(studentData);
  let worksheetStats = XLSX.utils.aoa_to_sheet(stats);

  // Applies column with and font types to correct cells in exported xlsx doument
  function styleSheets() {
    // Creates correct column width for all sheets
    worksheetResult["!cols"] = [
      { wch: 7 },
      { wch: 36 },
      { wch: 4 },
      { wch: 10 },
      { wch: 12 },
      { wch: 10 },
      { wch: 12 },
      { wch: 10 },
      { wch: 12 },
      { wch: 7 },
      { wch: 10 },
      { wch: 10 },
      { wch: 11 },
      { wch: 23 },
      { wch: 7 },
      { wch: 8 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
    ];

    worksheetForSwitch["!cols"] = [
      { wch: 6 },
      { wch: 36 },
      { wch: 4 },
      { wch: 7 },
      { wch: 10 },
      { wch: 10 },
      { wch: 11 },
      { wch: 23 },
      { wch: 20 },
      { wch: 7 },
      { wch: 10 },
      { wch: 15 },
      { wch: 20 },
    ];
    worksheetStats["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 30 },
      { wch: 50 },
    ];

    const headerFont = {
      font: {
        sz: 12,
        bold: true,
      },
    };

    const statsFont = {
      font: {
        sz: 12,
        bold: false,
        italic: true,
      },
    };

    worksheetResult["A1"].s = headerFont;
    worksheetResult["B1"].s = headerFont;
    worksheetResult["C1"].s = headerFont;
    worksheetResult["D1"].s = headerFont;
    worksheetResult["E1"].s = headerFont;
    worksheetResult["F1"].s = headerFont;
    worksheetResult["G1"].s = headerFont;
    worksheetResult["H1"].s = headerFont;
    worksheetResult["I1"].s = headerFont;
    worksheetResult["J1"].s = headerFont;
    worksheetResult["K1"].s = headerFont;
    worksheetResult["L1"].s = headerFont;
    worksheetResult["M1"].s = headerFont;
    worksheetResult["N1"].s = headerFont;
    worksheetResult["O1"].s = headerFont;
    worksheetResult["P1"].s = headerFont;
    worksheetResult["Q1"].s = headerFont;
    worksheetResult["R1"].s = headerFont;
    worksheetResult["S1"].s = headerFont;

    worksheetForSwitch["A1"].s = headerFont;
    worksheetForSwitch["B1"].s = headerFont;
    worksheetForSwitch["C1"].s = headerFont;
    worksheetForSwitch["D1"].s = headerFont;
    worksheetForSwitch["E1"].s = headerFont;
    worksheetForSwitch["F1"].s = headerFont;
    worksheetForSwitch["G1"].s = headerFont;
    worksheetForSwitch["H1"].s = headerFont;
    worksheetForSwitch["I1"].s = headerFont;
    worksheetForSwitch["J1"].s = headerFont;
    worksheetForSwitch["K1"].s = headerFont;
    worksheetForSwitch["L1"].s = headerFont;
    worksheetForSwitch["M1"].s = headerFont;

    worksheetStats["A1"].s = { font: { sz: 12, bold: true, italic: true } };
    worksheetStats["A2"].s = statsFont;
    worksheetStats["A3"].s = statsFont;
    worksheetStats["A4"].s = statsFont;
    worksheetStats["A5"].s = statsFont;
    worksheetStats["A6"].s = statsFont;

    hallHeaderIndexArray.pop();
    for (let i = 0; i < hallHeaderIndexArray.length; i++) {
      let cellStringA = "A" + hallHeaderIndexArray[i];
      let cellStringB = "B" + hallHeaderIndexArray[i];
      let cellStringC = "C" + hallHeaderIndexArray[i];
      let cellStringD = "D" + hallHeaderIndexArray[i];
      let cellStringE = "E" + hallHeaderIndexArray[i];
      let cellStringF = "F" + hallHeaderIndexArray[i];
      let cellStringG = "G" + hallHeaderIndexArray[i];

      worksheetStats[cellStringA].s = headerFont;
      worksheetStats[cellStringB].s = headerFont;
      worksheetStats[cellStringC].s = headerFont;
      worksheetStats[cellStringD].s = headerFont;
      worksheetStats[cellStringE].s = headerFont;
      worksheetStats[cellStringF].s = headerFont;
      worksheetStats[cellStringG].s = headerFont;
    }
  }

  styleSheets();

  workbook.SheetNames.push("Fordeling");
  workbook.Sheets["Fordeling"] = worksheetResult;
  workbook.SheetNames.push("Til Værelsesbyt");
  workbook.Sheets["Til Værelsesbyt"] = worksheetForSwitch;
  workbook.SheetNames.push("Statistik");
  workbook.Sheets["Statistik"] = worksheetStats;

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const fileData = new Blob([excelBuffer], { type: "xlsx" });
  return fileData;
}
