import Form from "react-bootstrap/Form";
import ProfileFormGroup from "./ProfileFormGroup";
import { useAuth } from "../../../setup/app-context-manager/AuthContext";
import { useFirestore } from "../../../setup/app-context-manager/FirestoreContext";

function ProfileForm() {
  const { currentUserAuth } = useAuth();
  const { currentUserData } = useFirestore();

  return (
    <Form>
      <ProfileFormGroup
        label="Navn"
        info={currentUserData?.name}
        id="userName"
      />
      <ProfileFormGroup
        label="Skole"
        info={currentUserData?.school}
        id="userSchool"
      />
      <ProfileFormGroup
        label="Mail"
        info={currentUserAuth?.email}
        id="userEmail"
      />
      <ProfileFormGroup
        label="Telefon"
        info={currentUserData?.number}
        id="userNumber"
      />
    </Form>
  );
}

export default ProfileForm;
