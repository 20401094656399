import "./App.css";
import React from "react";

import { BrowserRouter as Router } from "react-router-dom";
import RouterIndex from "./setup/routes-manager";
import "bootstrap/dist/css/bootstrap.min.css";
import NavbarGlobal from "./common/NavbarGlobal";

// Contexts
import { AuthProvider } from "./setup/app-context-manager/AuthContext";
import { VrlsSettingsProvider } from "./setup/app-context-manager/VrlsSettingsContext";
import { VrlsDataProvider } from "./setup/app-context-manager/VrlsDataContext";
import { FirestoreProvider } from "./setup/app-context-manager/FirestoreContext";
import { StorageProvider } from "./setup/app-context-manager/StorageContext";
import Footer from "./common/Footer";

function App() {
  return (
    <AuthProvider>
      <FirestoreProvider>
        <StorageProvider>
          <VrlsSettingsProvider>
            <VrlsDataProvider>
              <div className="App bg-light">
                <Router>
                  <NavbarGlobal />
                  <RouterIndex />
                  <Footer />
                </Router>
              </div>
            </VrlsDataProvider>
          </VrlsSettingsProvider>
        </StorageProvider>
      </FirestoreProvider>
    </AuthProvider>
  );
}

export default App;
