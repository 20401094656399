import { createContext, useContext } from "react";
import FileSaver from "file-saver";
import { ref, getBlob } from "firebase/storage";
import { storage } from "../../firebase-config";

const StorageContext = createContext({});

export function useStorage() {
  return useContext(StorageContext);
}

export const StorageProvider = ({ children }) => {
  // ref's to files in the database
  const studentExampleRef = ref(storage, "ElevlisteEksempel.xlsx");
  const roomExampleRef = ref(storage, "VærelseslisteEksempel.xlsx");
  const studentTemplateRef = ref(storage, "Elevskabelon.xlsx");
  const roomTemplateRef = ref(storage, "Værelseskabelon.xlsx");

  // List of files for smart-download
  const fileList = {
    ElevEksempel: studentExampleRef,
    VærelseEksempel: roomExampleRef,
    ElevSkabelon: studentTemplateRef,
    VærelseSkabelon: roomTemplateRef,
  };

  async function downloadFile(filename) {
    const ref = fileList[filename];
    const blob = await getBlob(ref);
    FileSaver.saveAs(blob, filename + ".xlsx");
  }

  const value = { downloadFile };

  return (
    <StorageContext.Provider value={value}>{children}</StorageContext.Provider>
  );
};
