import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SettingsCard from "./SettingsCard";

function AdvIndstillinger(props) {
  return (
    <Container>
      <Row>
        <Col xs={6}>
          <SettingsCard
            title="Standard"
            type="Avanceret - Standard"
            settings={props.settings}
            handleChange={props.handleChange}
          />
          <SettingsCard
            title="Vægtede gangparametre"
            type="Avanceret - Vægtede gangparametre"
            settings={props.settings}
            handleChange={props.handleChange}
          />
        </Col>
        <Col xs={6}>
          <SettingsCard
            title="Brug kun hårde indstillinger på 2-mandsværelser"
            type="Avanceret - Kun hård på 2"
            settings={props.settings}
            handleChange={props.handleChange}
          />
          <SettingsCard
            title="Kig kun på seneste værelse ved værelsesbyt"
            type="Avanceret - Kun seneste runde"
            settings={props.settings}
            handleChange={props.handleChange}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default AdvIndstillinger;
