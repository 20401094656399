import React from "react";
import { useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import ForgottenPasswordForm from "./ForgottenPasswordForm";
import { Link } from "react-router-dom";

function ForgottenPassword() {
  const mailRef = useRef();

  useEffect(() => {
    mailRef.current.focus();
  }, []);

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "88vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <h2 className="mt-3 text-center">Nulstil adgangskode</h2>
          <Card.Body>
            <ForgottenPasswordForm mailRef={mailRef} />
          </Card.Body>
          <Card.Footer className="text-center">
            <Link to="/login">Log ind</Link>
          </Card.Footer>
        </Card>
        <p className="text-center">
          Har du ikke en bruger? <Link to="/opret">Opret bruger</Link>
        </p>
      </div>
    </Container>
  );
}

export default ForgottenPassword;
