import { createContext, useContext, useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { useAuth } from "./AuthContext";

import {
  doc,
  setDoc,
  onSnapshot,
  updateDoc,
  addDoc,
  deleteDoc,
  collection,
} from "firebase/firestore";

import VrlsSettingsConverter from "../../handlers/VrlsSettingsConverter";

const FirestoreContext = createContext({});

export function useFirestore() {
  return useContext(FirestoreContext);
}

export const FirestoreProvider = ({ children }) => {
  const { currentUserAuth } = useAuth();
  const [currentUserData, setCurrentUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [currentUserTemplates, setCurrentUserTemplates] = useState();
  const [currentUserRuns, setCurrentUserRuns] = useState();

  // Function that creates a user
  function createUser(uid, newName, newNumber, newSchool) {
    return setDoc(doc(db, "users", uid), {
      name: newName,
      number: newNumber,
      school: newSchool,
      hasVrls: false,
    });
  }

  // Function that updates userDoc
  function updateUserDoc(data) {
    return updateDoc(doc(db, "users", currentUserAuth.uid), data);
  }

  // Adds or edits a settings template
  function setSettingsTemplate(name, originalSettings, uid) {
    var today = new Date();
    let settings = VrlsSettingsConverter(originalSettings);
    const tempTemplate = {
      name,
      settings,
      timeStamp: today.toLocaleString(),
    };
    if (uid) {
      return setDoc(
        doc(db, "users", currentUserAuth.uid, "vrlsSettingsTemplate", uid),
        tempTemplate
      );
    } else {
      return addDoc(
        collection(db, "users", currentUserAuth.uid, "vrlsSettingsTemplate"),
        tempTemplate
      );
    }
  }

  // Deletes a settings template
  function deleteSettingsTemplate(uid) {
    return deleteDoc(
      doc(db, "users", currentUserAuth.uid, "vrlsSettingsTemplate", uid)
    );
  }

  // Logs run statistics on global run database
  function logGlobalRunStatistics(settings, runStats, responses) {
    var today = new Date();
    const logData = {
      user: currentUserData.name,
      school: currentUserData.school,
      timeStamp: today.toLocaleString(),
      settings,
      runStats,
      serverResponses: responses,
    };
    return addDoc(collection(db, "runs"), logData);
  }

  // Logs run statistics on user database
  function logLocalRunStatistics(settings, runStats, responses) {
    var today = new Date();
    const logData = {
      user: currentUserData.name,
      school: currentUserData.school,
      timeStamp: today.toLocaleString(),
      settings,
      runStats,
      serverResponses: responses,
    };
    return addDoc(
      collection(db, "users", currentUserAuth.uid, "runs"),
      logData
    );
  }

  // Hook, that sets CurrentUserTemplate, whenever auth changes and does realtime-updates aswell
  useEffect(() => {
    // Only when the current user is authenticated
    if (currentUserAuth) {
      onSnapshot(
        doc(db, "users", currentUserAuth.uid),
        (doc) => {
          const userData = doc.data();
          setCurrentUserData(userData);
          setLoading(false);
        },
        (error) => {
          console.log(error.message);
        }
      );
      onSnapshot(
        collection(db, "users", currentUserAuth.uid, "vrlsSettingsTemplate"),
        (querySnapshot) => {
          var templateData = [];
          querySnapshot.forEach((doc) => {
            templateData.push({ ...doc.data(), id: doc.id });
          });
          setCurrentUserTemplates(templateData);
        },
        (error) => {
          console.log(error.message);
        }
      );
      onSnapshot(
        collection(db, "users", currentUserAuth.uid, "runs"),
        (querySnapshot) => {
          var runData = [];
          querySnapshot.forEach((doc) => {
            runData.push({ ...doc.data(), id: doc.id });
          });
          setCurrentUserRuns(runData);
        },
        (error) => {
          console.log(error.message);
        }
      );
    } else {
      // If the user is not authenticated, UserTemplates will be set to null
      setCurrentUserTemplates();
      setCurrentUserRuns();
      setCurrentUserData();
      setLoading(false);
    }
  }, [currentUserAuth]);

  const value = {
    currentUserData,
    currentUserTemplates,
    currentUserRuns,
    createUser,
    setSettingsTemplate,
    deleteSettingsTemplate,
    logGlobalRunStatistics,
    logLocalRunStatistics,
    updateUserDoc,
  };
  return (
    <FirestoreContext.Provider value={value}>
      {!loading && children}
    </FirestoreContext.Provider>
  );
};
