import React from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { useFirestore } from "../../../setup/app-context-manager/FirestoreContext";

function DashboardHome() {
  let navigate = useNavigate();
  const { currentUserData } = useFirestore();

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "88vh" }}
    >
      {currentUserData?.hasVrls ? (
        <Container className="mt-3 text-center">
          <h2>Velkommen til VRLS APP</h2>
          <p>Herunder finder du vores programmer, som du nemt kan bruge.</p>
          <p>Er du i tvivl om noget, er du velkommen til at kontakte os.</p>
          <Button
            onClick={() => {
              navigate("../../dashboard/vrls/home");
            }}
          >
            VRLS
          </Button>
        </Container>
      ) : (
        <Container className="mt-3 text-center">
          <h2>Velkommen til VRLS APP</h2>
          <p>Du har endnu ikke adgang til at bruge programmet.</p>
          <p>Kontakt os for at høre mere om mulighederne.</p>
        </Container>
      )}
    </Container>
  );
}

export default DashboardHome;
