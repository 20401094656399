function VrlsCheckImportErrors(importStats) {
  const errorList = [];

  if (importStats.wronglyPlacedBoysCount > 0) {
    let count = importStats.wronglyPlacedBoysCount;
    let msg;
    if (count === 1) {
      msg = "Der er forplaceret 1 dreng på et pigeværelse";
    }
    if (count > 1) {
      msg = "Der er forplaceret " + String(count) + " drenge på et pigeværelse";
    }
    let head = "Forplaceret elev: dreng på pigeværelse";
    let desc =
      "Enten er der sket en fejl på eleven eller på værelset. Tjek om eleven er en dreng, eller om værelset skal være et pigeværelse. \n" +
      "Fejlen gælder for følgende elever, der er blevet forplaceret på tilhørende værelse.";
    let dataList = importStats.wronglyPlacedBoys;
    dataList.splice(0, 0, ["Elevnummer", "Værelsesnavn"]); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.wronglyPlacedGirlsCount > 0) {
    let count = importStats.wronglyPlacedGirlsCount;
    let msg;
    if (count === 1) {
      msg = "Der er forplaceret 1 pige på et drengeværelse";
    }
    if (count > 1) {
      msg =
        "Der er forplaceret " + String(count) + " piger på et drengeværelse";
    }
    let head = "Forplaceret elev: Pige på drengeværelse";
    let desc =
      "Enten er der sket en fejl på eleven eller på værelset. Tjek om eleven er en pige, eller om værelset skal være et drengeværelse. \n" +
      "Fejlen gælder for følgende elever, der er blevet forplaceret på tilhørende værelse.";
    let dataList = importStats.wronglyPlacedGirls;
    dataList.splice(0, 0, ["Elevnummer", "Værelsesnavn"]); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.students !== importStats.beds) {
    let msg = "Antallet af senge passer ikke med antallet af elever";
    let head = "Antal af senge og elever";
    let desc =
      "Tjek om alle elever har fået et elevnummer og evt. om alle værelser har fået et værelsesnavn. \n" +
      "Tjek også gerne om alle elever har fået et korrekt køn.";
    let dataList = [];
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.boys !== importStats.boyBeds) {
    let msg = "Antallet af drengesenge passer ikke med antallet af drenge";
    let head = "Antal af senge og elever: drenge";
    let desc =
      "Tjek om alle drenge har fået et elevnummer og evt. om alle drengeværelser har fået et værelsesnavn. \n" +
      "Tjek også gerne om alle elever har fået et korrekt køn.";
    let dataList = [];
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.girls !== importStats.girlBeds) {
    let msg = "Antallet af pigesenge passer ikke med antallet af piger";
    let head = "Antal af senge og elever: Piger";
    let desc =
      "Tjek om alle piger har fået et elevnummer og evt. om alle pigeværelser har fået et værelsesnavn. \n" +
      "Tjek også gerne om alle elever har fået et korrekt køn.";
    let dataList = [];
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.duplicateStudentNumbers.length !== 0) {
    let msg = "Der findes mere end en elev med samme elevnummer";
    let head = "Duplikerede elevnumre";
    let desc =
      "Hver elev skal have sit eget unikke elevnummer. \n" +
      "Vi har registreret at følgende elevnumre forekommer flere gange.";
    let dataList = importStats.duplicateStudentNumbers;
    dataList.splice(0, 0, "Elevnummer"); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.nameOfWrongStudentNumbers.length !== 0) {
    let msg =
      String(importStats.nameOfWrongStudentNumbers.length) +
      " elever har ikke et gyldigt elevnummer";
    let head = "Forkert elevnummer";
    let desc =
      "Elevnummre må kun bestå af tal. Følgende elever har ikke et gyldigt elevnummer.";
    let dataList = importStats.nameOfWrongStudentNumbers;
    dataList.splice(0, 0, "Elevnavn"); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.wrongStudentGender.length !== 0) {
    let msg =
      String(importStats.wrongStudentGender.length) +
      " elever har ikke et gyldigt køn";
    let head = "Forkert elevkøn";
    let desc =
      "For drenge skal kønnet skrives som 'M' og for piger skal kønnet skrives som 'K'. \n" +
      "Vi har fundet fejl på følgende elever.";
    let dataList = importStats.wrongStudentGender;
    dataList.splice(0, 0, "Elevnummer"); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.wrongRoomGender.length !== 0) {
    let msg =
      String(importStats.wrongRoomGender.length) +
      " værelser har ikke et gyldigt køn";
    let head = "Forkert værelseskøn";
    let desc =
      "For drengeværelser skal kønnet skrives som 'M' og for pigeværelser skal kønnet skrives som 'K'. \n" +
      "Vi har fundet fejl på følgende værelser.";
    let dataList = importStats.wrongRoomGender;
    dataList.splice(0, 0, "Værelsesnavn"); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.wrongPostalCode.length !== 0) {
    let msg =
      String(importStats.wrongPostalCode.length) +
      " elever har ikke et gyldigt postnummer";
    let head = "Forkert postnummer";
    let desc =
      "Postnumre må kun bestå af tal. Følgende elever har ikke et gyldigt postnummer.";
    let dataList = importStats.wrongPostalCode;
    dataList.splice(0, 0, "Elevnummer"); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.nameOfWrongRoomSize.length !== 0) {
    let msg =
      String(importStats.nameOfWrongRoomSize.length) +
      " værelser har ikke en gyldig værelsesstørrelse";
    let head = "Forkert værelsesstørrelse";
    let desc =
      "Værelsesstørrelser må kun bestå af tal. Hvis der ikke skal bo nogle elever på værelset, skal værelsesstørrelsen sættes til 0. \n" +
      "Følgende værelser har ikke en gyldig værelsesstørrelse.";
    let dataList = importStats.nameOfWrongRoomSize;
    dataList.splice(0, 0, "Værelsesnavn"); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.duplicateRoomNames.length !== 0) {
    let msg =
      String(importStats.duplicateRoomNames.length) +
      " værelser har samme værelsesnavn";
    let head = "Duplikerede værelsesnavne";
    let desc =
      "Hvert værelse skal have sit eget unikke værelsesnavn. \n" +
      "Vi har registreret at følgende værelsesnavne forekommer flere gange.";
    let dataList = importStats.duplicateRoomNames;
    dataList.splice(0, 0, "Værelsesnavn"); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  if (importStats.MissingPrevRooms.length !== 0) {
    let msg =
      String(importStats.MissingPrevRooms.length) +
      " tidligere værelser kan ikke findes";
    let head = "Manglende tidligere værelser";
    let desc =
      "Alle tidligere værelser skal være noteret i værelsesfilen. Hvis i har et eller flere værelser, der ikke længere skal bruges, skal de stadig stå i værelsesfilen. \n" +
      "I kan sætte værelsesstørrelsen til 0, for at der ikke kommer til at blive fordelt nogle elever på de lukkede værelser. Følgende værelser mangler i værelsesfilen.";
    let dataList = importStats.MissingPrevRooms;
    dataList.splice(0, 0, "Værelsesnavn"); // Tilføj overskrift til modaldata
    errorList.push([msg, head, desc, dataList]);
  }

  return errorList;
}

export default VrlsCheckImportErrors;
