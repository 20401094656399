import axios from "axios";

// Creates a unique ID of length length
function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

let postCode = [
  {
    serverResponse: 200,
    name: "VrlsResponse",
    error: false,
    continue: true,
    message: "Forbindelse til serveren er modtaget",
  },
  {
    serverResponse: 250,
    name: "VrlsResponse",
    error: false,
    continue: true,
    message: "Begynder sortering",
  },
  {
    serverResponse: 251,
    name: "VrlsResponse",
    error: false,
    continue: true,
    message: "Sortering er i gang",
  },
  {
    serverResponse: 252,
    name: "VrlsResponse",
    error: false,
    continue: true,
    message: "Sortering er i kø",
  },
  {
    serverResponse: 253,
    name: "VrlsResponse",
    error: false,
    continue: false,
    message: "Sortering er modtaget",
  },
  {
    serverResponse: 280,
    name: "VrlsResponse",
    error: true,
    continue: false,
    message: "Fordeling eksisterer ikke",
  },
  {
    serverResponse: 281,
    name: "VrlsResponse",
    error: true,
    continue: false,
    message: "Ikke godkendt recaptcha token",
  },
  {
    serverResponse: 282,
    name: "VrlsResponse",
    error: true,
    continue: false,
    message: "Ikke godkendt recaptcha token",
  },
  {
    serverResponse: 500,
    name: "VrlsResponse",
    error: true,
    continue: false,
    message: "Kunne ikke få kontakt til serveren",
  },
];

export default async function PostAndFetchAxios(
  students,
  rooms,
  settings,
  token,
  setServerMessage,
  setLoading
) {
  // Begins work
  setServerMessage({
    msg: "Sender data til serveren",
    var: "primary",
    spinner: true,
  });

  // Initializing variables
  const browserId = makeid(30);
  const fetchData = JSON.stringify(browserId);
  const postData = JSON.stringify([students, rooms, settings, browserId]);
  let requestCounter = 0;
  let responseLog = [];

  function postCodeHandler(response) {
    responseLog.push(response);
    return postCode.find((element) => {
      if (element.serverResponse === response.status) {
        return element;
      }
    });
  }

  // Initializing the axios client
  const client = axios.create({
    baseURL: "https://vrls-backend.herokuapp.com/",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  // Internal function, to handle messaging on the UID
  function handleMessage(response) {
    let variant = "default";
    let spinner = true;
    if (response.error === true) {
      variant = "danger";
      spinner = false;
      setLoading(false);
    } else if (response.name === "AxiosError") {
      variant = "danger";
      spinner = false;
      setLoading(false);
    } else if (response.error === false) {
      variant = "success";
    }
    setServerMessage({
      msg: response.message,
      var: variant,
      spinner,
    });
  }

  // Wrapper around the fetch
  async function handleFetch() {
    const fetchResponse = await client
      .post("/export", fetchData)
      .catch((err) => {
        handleMessage(err);
      });
    return fetchResponse;
  }

  const postResponse = await client.post("/import", postData).catch((err) => {
    handleMessage(err);
    console.log("Catch Fejl");
    return;
  });

  responseLog.push(postResponse);

  // If postResponse, then we continue
  if (postResponse.status === 250) {
    handleMessage(postCodeHandler(postResponse));
    while (requestCounter < 60) {
      // Waits 1 second before continuing
      await new Promise((resolve) => setTimeout(resolve, 2000));

      // Fetches data from the server
      let serverResponse = await handleFetch();

      // Interpres status and handles messaging
      let serverStatus = postCodeHandler(serverResponse);
      handleMessage(serverStatus);

      // Logical statement to check whether to continue or to stop
      if (serverStatus.continue === false) {
        return [serverResponse.data, responseLog];
      }

      // Increment requestData
      requestCounter++;
    }
    if (requestCounter >= 60) {
      setServerMessage({
        msg: "Vi kunne desværre ikke løse din fordeling på 1 minut",
        var: "danger",
        spinner: false,
      });
      return;
    }
  } else if (postResponse) {
    console.log(postResponse);
    handleMessage(postCodeHandler(postResponse));
    return;
  } else {
    console.log("Skør fejl");
    return;
  }

  return;
}
