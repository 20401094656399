import React from "react";
import { useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import SignUpForm from "./SignUpForm";
import { Link } from "react-router-dom";

function SignUp() {
  const nameRef = useRef();
  const schoolRef = useRef();
  const numberRef = useRef();
  const mailRef = useRef();
  const passwordRef = useRef();
  const confirmpasswordRef = useRef();

  useEffect(() => {
    mailRef.current.focus();
  }, []);

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "88vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <h2 className="mt-3 text-center">Opret Bruger</h2>
          <Card.Body>
            <SignUpForm
              mailRef={mailRef}
              passwordRef={passwordRef}
              confirmpasswordRef={confirmpasswordRef}
              nameRef={nameRef}
              schoolRef={schoolRef}
              numberRef={numberRef}
              pattern="[0,9]{8}"
            />
          </Card.Body>
          <Card.Footer className="text-center">
            Har du allerede en bruger? <Link to="/login">Login</Link>
          </Card.Footer>
        </Card>
      </div>
    </Container>
  );
}

export default SignUp;
