import React, { createContext, useState, useContext, useEffect } from "react";
import VrlsCheckImport from "../../handlers/VrlsCheckImport";
import VrlsCheckImportErrors from "../../handlers/VrlsCheckImportErrors";
import * as XLSX from "xlsx";

const VrlsDataContext = createContext(null);

export function useData() {
  return useContext(VrlsDataContext);
}

export const VrlsDataProvider = ({ children }) => {
  // Logik for Indstillinger
  const [students, setStudents] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [importInfo, setImportInfo] = useState({});
  const [errors, setErrors] = useState([]);
  const [importComplete, setImportComplete] = useState(false);

  // Logik for VRLS-navbar
  const [activeStep, setActiveStep] = useState(0);

  function importStudents(file) {
    var f = file[0];
    var reader = new FileReader();

    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      let ws;
      if (readedData.SheetNames.includes("Til Værelsesbyt")) {
        ws = readedData.Sheets["Til Værelsesbyt"];
      } else {
        let wsname = readedData.SheetNames[0];
        ws = readedData.Sheets[wsname];
      }

      // Local variables
      let tempStudents = [];
      let maxStudentLength = 12;
      let lastStudentRow = 0;

      // Convert array to json
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      // Turns everything into strings and uppercase
      for (let i = 1; i < dataParse.length; i++) {
        let currentStudent = dataParse[i];
        tempStudents[i] = [];
        for (let j = 0; j <= maxStudentLength; j++) {
          if (j <= currentStudent.length) {
            let cel = currentStudent[j];
            switch (typeof cel) {
              case "number":
                tempStudents[i][j] = cel.toString().replace(".", ",").trim();
                break;
              case "null":
                tempStudents[i][j] = "";
                break;
              case "string":
                tempStudents[i][j] = cel.toUpperCase().trim();
                break;
              default:
                tempStudents[i][j] = "";
                break;
            }
          } else {
            tempStudents[i][j] = "";
          }
        }
        if ((tempStudents[i][0] !== "") & (i > lastStudentRow)) {
          lastStudentRow = i;
        }
      }
      let temp = tempStudents.slice(0, lastStudentRow + 1);
      setStudents(temp);
    };
    reader.readAsBinaryString(f);
  }

  function importRooms(file) {
    var f = file[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      let tempRooms = [];
      let maxRoomLength = 5;
      let lastRoomRow = 0;
      // Turns everything into strings and uppercase
      for (let i = 1; i < dataParse.length; i++) {
        let currentRooms = dataParse[i];
        tempRooms[i] = [];
        for (let j = 0; j <= maxRoomLength; j++) {
          if (j <= currentRooms.length) {
            let cel = currentRooms[j];
            switch (typeof cel) {
              case "number":
                tempRooms[i][j] = cel.toString().replace(".", ",").trim();
                break;
              case "null":
                tempRooms[i][j] = "";
                break;
              case "string":
                if (cel === "") {
                  tempRooms[i][j] = "";
                  break;
                } else {
                  tempRooms[i][j] = cel.toUpperCase().trim();
                  break;
                }
              default:
                tempRooms[i][j] = "";
                break;
            }
          } else {
            tempRooms[i][j] = "";
          }
        }
        if ((tempRooms[i][0] !== "") & (i > lastRoomRow)) {
          lastRoomRow = i;
        }
      }
      let temp = tempRooms.slice(0, lastRoomRow + 1);
      setRooms(temp);
    };
    reader.readAsBinaryString(f);
  }

  useEffect(() => {
    if (students.length !== 0 && rooms.length !== 0) {
      const tempImportInfo = VrlsCheckImport(students, rooms);
      setImportInfo(tempImportInfo);
      setImportComplete(true);
    }
  }, [students, rooms]);

  useEffect(() => {
    if (Object.keys(importInfo).length !== 0) {
      const tempErrors = VrlsCheckImportErrors(importInfo);
      setErrors(tempErrors);
    }
  }, [importInfo]);

  function clearData() {
    setStudents([]);
    setRooms([]);
    setImportComplete(false);
    setErrors([]);
    setImportInfo({});
  }

  const value = {
    students,
    importStudents,
    rooms,
    importRooms,
    clearData,
    importInfo,
    errors,
    importComplete,
    activeStep,
    setActiveStep,
  };

  return (
    <VrlsDataContext.Provider value={value}>
      {children}
    </VrlsDataContext.Provider>
  );
};
