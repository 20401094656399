import React from "react";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

function SettingsForm(props) {
  return (
    <>
      {props.settings.map((element) => {
        if (element.type === props.type && element.formtype === "check") {
          return (
            <OverlayTrigger
              key={element.id}
              placement={"auto"}
              overlay={
                <Popover id={element.id}>
                  <Popover.Body>{element.tooltipText}</Popover.Body>
                </Popover>
              }
              trigger={"focus"}
              delay={{ show: 100, hide: 100 }}
            >
              <Container>
                <Form.Check
                  id={element.id}
                  label={element.showName}
                  className="justify-content-start"
                  checked={element.value}
                  onChange={(event) => {
                    props.handleChange(event);
                  }}
                />
              </Container>
            </OverlayTrigger>
          );
        } else if (
          element.type === props.type &&
          element.formtype === "range"
        ) {
          return (
            <OverlayTrigger
              key={element.id}
              placement={"auto"}
              overlay={
                <Popover id={element.id}>
                  <Popover.Body>{element.tooltipText}</Popover.Body>
                </Popover>
              }
            >
              <Form.Group as={Row} controlId={element.id}>
                <Col sm={4}>
                  <Form.Label sm={5}>{element.showName}</Form.Label>
                </Col>
                <Col sm={3} className="text-center">
                  <Form.Label sm={2}>{element.value}</Form.Label>
                </Col>
                <Col sm={5}>
                  <Form.Range
                    disabled={element.disabled}
                    id={element.id}
                    className="justify-content-start"
                    value={element.value}
                    min={element.min}
                    max={element.max}
                    step={element.step}
                    onChange={(event) => {
                      props.handleChange(event);
                    }}
                  />
                </Col>
              </Form.Group>
            </OverlayTrigger>
          );
        } else {
          return;
        }
      })}
    </>
  );
}

export default SettingsForm;
