function VrlsSettingsConverter(settings) {
  let settingsObject = {
    checkboxes: {},
    advanced: {},
  };

  settings.map((elem) => {
    if (elem.serverType === "checkboxes") {
      settingsObject.checkboxes[elem.serverName] = elem.value;
    } else if (elem.serverType === "advanced") {
      settingsObject.advanced[elem.serverName] = elem.value;
    }
  });

  return settingsObject;
}

export default VrlsSettingsConverter;
