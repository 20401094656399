import React, { useState, useRef, useEffect } from "react";
import { useData } from "../../../../setup/app-context-manager/VrlsDataContext";
import HelpModal from "./HelpModal";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

function VrlsElevImport() {
  // Logik for hjælpemodal
  const [showHelpModal, setShowHelpModal] = useState(false);
  const handleClose = () => setShowHelpModal(false);
  const handleShow = () => setShowHelpModal(true);

  // Logik for filbekræftelse
  const [fileConfirm, setFileConfirm] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  // Import af DataContext
  const { importStudents, students, setActiveStep } = useData();

  const navigate = useNavigate();

  // Logik for filupload
  const fileRef = useRef();

  function handleFile(e) {
    e.preventDefault();
    setFileLoading(true);
    const file = fileRef.current.files;
    importStudents(file);
  }

  useEffect(() => {
    if (students.length !== 0) {
      setFileConfirm(true);
      setFileLoading(false);
    }
  }, [students]);

  return (
    <>
      <Container className="d-flex " style={{ minHeight: "82vh" }}>
        <Container className="text-center">
          <Row>
            <Col></Col>
            <Col xl={5} xs={6} className="justify-content-center">
              <h1 className="my-3">Elevimport</h1>
              <Form className="py-2">
                <Form.Control
                  type="file"
                  ref={fileRef}
                  onChange={handleFile}
                  accept=".xlsx, .xls"
                  multiple={false}
                />
              </Form>
              {fileConfirm ? (
                <Alert variant="success" className="my-3">
                  Filen er blevet læst
                </Alert>
              ) : fileLoading ? (
                <Alert variant="secondary" className="my-3">
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </Alert>
              ) : (
                <Alert variant="secondary" className="my-3">
                  Vælg en fil for at gå videre
                </Alert>
              )}
            </Col>
            <Col></Col>
          </Row>
          <Row className="mt-3">
            <Col></Col>
            <Col xl={5} xs={6}>
              <Row>
                <Col className="d-flex justify-content-start">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setActiveStep(0);
                      navigate("../home");
                    }}
                  >
                    Tilbage
                  </Button>
                </Col>
                <Col>
                  <Button variant="outline-secondary" onClick={handleShow}>
                    Hjælp
                  </Button>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button
                    disabled={!fileConfirm}
                    onClick={() => {
                      setActiveStep(2);
                      navigate("../vaerelseimport");
                    }}
                  >
                    Videre
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </Container>
      <HelpModal show={showHelpModal} handleClose={handleClose} />
    </>
  );
}

export default VrlsElevImport;
