import React from "react";
import { useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import UpdateProfileForm from "./UpdateProfileForm";
import { Link } from "react-router-dom";

function UpdateProfile() {
  const nameRef = useRef();
  const schoolRef = useRef();
  const emailRef = useRef();
  const numberRef = useRef();

  useEffect(() => {
    nameRef.current.focus();
  }, []);

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "88vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <h1 className="mt-3 text-center">Opdater profil</h1>
          <Card.Body>
            <UpdateProfileForm
              nameRef={nameRef}
              schoolRef={schoolRef}
              emailRef={emailRef}
              numberRef={numberRef}
            />
          </Card.Body>
          <Card.Footer className="text-center">
            <Link to="/dashboard/profil">Fortryd</Link>
          </Card.Footer>
        </Card>
      </div>
    </Container>
  );
}

export default UpdateProfile;
