import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ItemList from "./ItemList";
import { useData } from "../../../../setup/app-context-manager/VrlsDataContext";
import HelpModal from "./HelpModal";
import ItemModal from "./ItemModal";
import ErrorModal from "./ErrorModal";
import ErrorAlert from "./ErrorAlert";

function VrlsGodkend() {
  // Import af DataContext
  const { importInfo, errors, setActiveStep } = useData();
  const navigate = useNavigate();

  // Logik for hjælpemodal
  const [showHelpModal, setShowHelpModal] = useState(false);
  const handleHelpClose = () => setShowHelpModal(false);
  const handleShow = () => setShowHelpModal(true);

  // Logik for fejlmodal
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [infoErrorModal, setInfoErrorModal] = useState([]);
  const handleErrorShow = (errObj) => {
    setInfoErrorModal(errObj);
    setShowErrorModal(true);
  };
  const handleErrorClose = () => {
    setShowErrorModal(false);
    setInfoErrorModal([]);
  };

  // Logik for infomodal
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalData, setInfoModalData] = useState([]);
  const handleInfoShow = (name, group, groupname, type) => {
    setInfoModalData([name, group, groupname, type]);
    setShowInfoModal(true);
  };
  const handleInfoClose = () => {
    setShowInfoModal(false);
  };

  return (
    <>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "82vh" }}
      >
        <Container className="mt-3 text-center">
          <Row>
            <Col></Col>
            <Col xl={5} xs={6} className="justify-content-center">
              <h1>Godkend</h1>
            </Col>
            <Col></Col>
          </Row>
          <Row className="my-3">
            <Container>
              <ErrorAlert
                errors={errors}
                handleErrorShow={handleErrorShow}
              ></ErrorAlert>
            </Container>
          </Row>
          <Row>
            <Col xs={4} className="justify-content-center">
              <Card>
                <Card.Header>Elevinformation</Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ItemList name="Elever" value={importInfo.students} />
                    <ItemList name="Drenge" value={importInfo.boys} />
                    <ItemList name="Piger" value={importInfo.girls} />
                    <ItemList
                      name="Forplacerede Drenge"
                      value={importInfo.prePlacedBoysCount}
                      group={importInfo.prePlacedBoys}
                      groupname="Elevnummer"
                      modaltype="adv"
                      handleInfoShow={handleInfoShow}
                    />
                    <ItemList
                      name="Forplacerede Piger"
                      value={importInfo.prePlacedGirlsCount}
                      group={importInfo.prePlacedGirls}
                      groupname="Elevnummer"
                      modaltype="adv"
                      handleInfoShow={handleInfoShow}
                    />
                    <ItemList
                      name="Sårbare"
                      value={importInfo.vulnCount}
                      group={importInfo.vuln}
                    />
                    <ItemList
                      name="Andenårs"
                      value={importInfo.secondYearCount}
                      group={importInfo.secondYear}
                    />
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4} className="justify-content-center">
              <Card>
                <Card.Header>Værelsesinformation</Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ItemList name="Senge" value={importInfo.beds} />
                    <ItemList name="Drengesenge" value={importInfo.boyBeds} />
                    <ItemList name="Pigesenge" value={importInfo.girlBeds} />
                    <ItemList
                      name="Gange"
                      value={importInfo.hallsCount}
                      group={importInfo.halls}
                      groupname="værelser"
                      modaltype="simple"
                      handleInfoShow={handleInfoShow}
                    />
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4} className="justify-content-center">
              <Card>
                <Card.Header>Anden information</Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ItemList
                      name="Klasser"
                      value={importInfo.klasserCount}
                      group={importInfo.klasser}
                      groupname="elever"
                      modaltype="simple"
                      handleInfoShow={handleInfoShow}
                    />
                    <ItemList
                      name="Linjer"
                      value={importInfo.linesCount}
                      group={importInfo.lines}
                      groupname="elever"
                      modaltype="simple"
                      handleInfoShow={handleInfoShow}
                    />
                    <ItemList
                      name="Grupper"
                      value={importInfo.familyGroupsCount}
                      group={importInfo.familyGroups}
                      groupname="elever"
                      modaltype="simple"
                      handleInfoShow={handleInfoShow}
                    />
                    <ItemList
                      name="Brugerdefinerede Grupper"
                      value={importInfo.socialGroupsCount}
                      group={importInfo.socialGroups}
                      groupname="elever"
                      modaltype="simple"
                      handleInfoShow={handleInfoShow}
                    />
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="d-flex justify-content-center">
              <Button
                variant="secondary"
                onClick={() => {
                  setActiveStep(2);
                  navigate("../vaerelseimport");
                }}
              >
                Tilbage
              </Button>
            </Col>
            <Col>
              <Button variant="outline-secondary" onClick={handleShow}>
                Hjælp
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                onClick={() => {
                  setActiveStep(4);
                  navigate("../indstillinger");
                }}
              >
                Godkend
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
      <ErrorModal
        show={showErrorModal}
        handleClose={handleErrorClose}
        data={infoErrorModal}
      />
      <HelpModal show={showHelpModal} handleClose={handleHelpClose} />
      <ItemModal
        show={showInfoModal}
        handleClose={handleInfoClose}
        props={infoModalData}
      />
    </>
  );
}

export default VrlsGodkend;
