import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import NavbarVrls from "../../common/NavbarVrls";
import { useFirestore } from "../app-context-manager/FirestoreContext";

export const VrlsRoute = () => {
  const { loading, currentUserData } = useFirestore();

  if (loading) {
    return <div>Loading...</div>;
  } else if (currentUserData.hasVrls === true) {
    return (
      <>
        <NavbarVrls />
        <Outlet />
      </>
    );
  } else {
    return <Navigate to="/dashboard/home" />;
  }
};
