import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "88vh" }}
    >
      <Container className="w-100 text-center" style={{ maxWidth: "700px" }}>
        <Card>
          <h1>Fejl: 404</h1>
          <p>Den side, du prøver at tilgå findes desværre ikke.</p>
          <Link to="/dashboard/home">Gå til Dashboard</Link>
        </Card>
      </Container>
    </Container>
  );
}

export default ErrorPage;
