import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useState } from "react";
import { useAuth } from "../../setup/app-context-manager/AuthContext";
import { useNavigate } from "react-router-dom";

function LoginForm(props) {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  let navigate = useNavigate();

  async function handleLogin(e) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await login(props.mailRef.current.value, props.passwordRef.current.value);
      navigate("../dashboard/home");
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        setError("Forkert adgangskode");
      } else if (error.code === "auth/user-not-found") {
        setError("Forkert mail");
      } else if (error.code === "auth/invalid-email") {
        setError("Skriv email");
      } else if (error.code === "auth/internal-error") {
        setError("Skriv adgangskode");
      } else if (error.code === "auth/too-many-requests") {
        setError("For mange fejlslagne forsøg");
      } else {
        setError(error.code);
      }
    }
    setLoading(false);
  }

  return (
    <Form onSubmit={handleLogin}>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" ref={props.mailRef} placeholder="Email" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Adgangskode</Form.Label>
        <Form.Control
          type="password"
          placeholder="Adgangskode"
          ref={props.passwordRef}
        />
      </Form.Group>
      <Button
        disabled={loading}
        variant="primary"
        type="submit"
        className="w-100"
      >
        Login
      </Button>
    </Form>
  );
}

export default LoginForm;
