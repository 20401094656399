// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzGdiQWjqZonL3a8c0XQxgFjjNU6IY0a4",
  authDomain: "vrls-dist.firebaseapp.com",
  projectId: "vrls-dist",
  storageBucket: "vrls-dist.appspot.com",
  messagingSenderId: "1079591396615",
  appId: "1:1079591396615:web:029e84b993952ac06c91a2",
  measurementId: "G-1Y6PPFTGJ0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
