import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useFirestore } from "../../../../setup/app-context-manager/FirestoreContext";
import { useSettings } from "../../../../setup/app-context-manager/VrlsSettingsContext";

function SkabelonDropdown() {
  const { currentUserTemplates } = useFirestore();
  const { applyTemplate } = useSettings();

  return (
    <>
      {currentUserTemplates.map((template) => {
        return (
          <Dropdown.Item
            key={template.name}
            onClick={() => {
              applyTemplate(template);
            }}
          >
            {template.name}
          </Dropdown.Item>
        );
      })}
    </>
  );
}

export default SkabelonDropdown;
