import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ProfileFormGroup(props) {
  return (
    <Form.Group as={Row} className="mb-2" controlId={props.id}>
      <Form.Label column sm="4">
        {props.label}
      </Form.Label>
      <Col sm="8">
        <Form.Control plaintext readOnly defaultValue={props.info} />
      </Col>
    </Form.Group>
  );
}

export default ProfileFormGroup;
