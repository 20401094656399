export const IndstillingerList = (type) => {
  var list = [
    {
      id: 1,
      showName: "Klasse",
      tooltipText: "Elever fra samme klasse kan ikke bo på værelse sammen",
      type: "Værelse",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "hardKlasse",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 2,
      showName: "Linje",
      tooltipText: "Elever fra samme linje kan ikke bo på værelse sammen",
      type: "Værelse",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "hardLine",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 3,
      showName: "Gruppe",
      tooltipText: "Elever fra samme gruppe kan ikke bo på værelse sammen",
      type: "Værelse",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "hardFamilyGroup",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 4,
      showName: "Andenårselever",
      tooltipText: "Andenårselever kan ikke bo på værelse sammen",
      type: "Værelse",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "hardSecondYear",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 5,
      showName: "Sårbare elever",
      tooltipText: "Sårbare elever kan ikke bo på værelse sammen",
      type: "Værelse",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "hardVulnerable",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 6,
      showName: "Postnummer",
      tooltipText: "Elever fra samme postnummer kan ikke bo på værelse sammen",
      type: "Værelse",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "hardPostnr",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 7,
      showName: "Kommuner",
      tooltipText: "Elever fra samme kommune kan ikke bo på værelse sammen",
      type: "Værelse",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "hardKommune",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 8,
      showName: "Brugerdefineret gruppe",
      tooltipText:
        "Elever fra samme brugerdefinerede gruppe kan ikke bo på værelse sammen",
      type: "Værelse",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "hardSocialGroup",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 9,
      showName: "Klasse",
      tooltipText:
        "Elever fra samme klasse bliver fordelt ligeligt udover gangene",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "softKlasse",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 10,
      showName: "Linje",
      tooltipText:
        "Elever fra samme linje bliver fordelt ligeligt udover gangene",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "softLine",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 11,
      showName: "Gruppe",
      tooltipText:
        "Elever fra samme gruppe bliver fordelt ligeligt udover gangene",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "softFamilyGroup",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 12,
      showName: "Andenårselever",
      tooltipText: "Andenårselever bliver fordelt ligeligt udover gangene",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "softSecondYear",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 13,
      showName: "Sårbare elever",
      tooltipText: "Sårbare elever bliver fordelt ligeligt udover gangene",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "softVulnerable",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 14,
      showName: "Landsdele",
      tooltipText:
        "Elever fra samme landsdel bliver fordelt ligeligt udover gangene",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "softLandsdel",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 15,
      showName: "Opdel Sjælland",
      tooltipText: "Opdeler elever fra Sjælland i yderligere tre dele",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "opdelSjælland",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 16,
      showName: "Opdel Jylland",
      tooltipText: "Opdeler elever fra Jylland i yderligere fire dele",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "opdelJylland",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 54,
      showName: "Kommune",
      tooltipText:
        "Elever fra samme kommune bliver fordelt ligeligt udover gangene",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "softKommune",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 17,
      showName: "Brugerdefineret gruppe",
      tooltipText:
        "Elever fra samme brugerdefineret gruppe bliver fordelt ligeligt udover gangene",
      type: "Gang",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "softSocialGroup",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 18,
      showName: "Kan ikke bo med tidl. roomie",
      tooltipText: "Elever kan ikke bo på værelse med deres tidligere roomies",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "avoidPrevRoomieRoom",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 56,
      showName: "Kan ikke bo på tidl. værelse",
      tooltipText: "Elever kan ikke bo på deres tidligere værelser",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "avoidPrevRoom",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 55,
      showName: "Kan ikke bo på gang med tidl. roomie",
      tooltipText: "Elever kan ikke bo på gang med deres tidligere roomies",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "avoidPrevRoomieHall",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 19,
      showName: "Kan ikke bo med tidl. gangkammerater",
      tooltipText:
        "Elever kan ikke bo på værelse med deres tidligere gangkammerater",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "avoidPrevHallMates",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 20,
      showName: "Kan ikke bo på tidl. gang",
      tooltipText: "Elever kan ikke bo på deres tidligere gang",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "avoidPrevHall",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 21,
      showName: "Opdeling af elever fra tidl. gange",
      tooltipText:
        "Elever, der tidligere har boet på samme gang, bliver fordelt ligeligt udover skolen",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "splitPrevHall",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 22,
      showName: "Undgå store værelser",
      tooltipText:
        "Elever, der tidligere har boet på værelser med 3 eller flere senge, kan kun bo på 2-persons værelse",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "avoidBigRooms",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 23,
      showName: "Undgå tidl. værelsesstørrelse",
      tooltipText:
        "Elever, der tidligere har boet på værelser med 3 eller flere senge, kan IKKE bo på et værelse med samme antal senge",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "avoidRoomSize",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 24,
      showName: "Vigtigt område: Blød fordeling",
      tooltipText:
        "Cirka halvdelen af de elever, der IKKE har boet på det vigtige område, kommer til at bo på det vigtige område",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "areaSoft",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 25,
      showName: "Vigtigt område: Hård fordeling",
      tooltipText:
        "Alle elever, der ikke tidligere har boet på det vigtige område, kommer til at bo på det vigtige område",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "areaHard",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 26,
      showName: "Vigtigt område: Omvendt fordeling",
      tooltipText:
        "Alle elever, der tidligere kun har boet på det vigtige område, kommer ikke til at bo på det vigtige område",
      type: "Værelsesbyt",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "areaReverse",
      serverType: "checkboxes",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 27,
      showName: "Antal fordelingsforsøg",
      tooltipText: "Antal forsøg, der i alt bruges på at finde en fordeling",
      type: "Avanceret - Standard",
      formtype: "range",
      min: 50000,
      value: 400000,
      max: 1000000,
      step: 10000,
      disabled: false,
      serverName: "maxAttempts",
      serverType: "advanced",
      defaultValue: 400000,
      defaultDisabled: false,
    },
    {
      id: 28,
      showName: "Bløde parametre hyppighed",
      tooltipText: "",
      type: "Avanceret - Standard",
      formtype: "range",
      min: 500,
      value: 2500,
      max: 10000,
      step: 100,
      disabled: false,
      serverName: "hallIncAttempts",
      serverType: "advanced",
      defaultValue: 2500,
      defaultDisabled: false,
    },
    {
      id: 29,
      showName: "Bløde parametre % forøgelse",
      tooltipText: "",
      type: "Avanceret - Standard",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: false,
      serverName: "hallIncPercent",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: false,
    },
    {
      id: 30,
      showName: "Postnummer afstand",
      tooltipText:
        "Afstanden mellem to elevers postnumre, for at de må bo sammen",
      type: "Avanceret - Standard",
      formtype: "range",
      min: 0,
      value: 0,
      max: 100,
      step: 1,
      disabled: false,
      serverName: "postnrInterval",
      serverType: "advanced",
      defaultValue: 0,
      defaultDisabled: false,
    },
    {
      id: 31,
      showName: "Klasse",
      tooltipText: "",
      type: "Avanceret - Kun hård på 2",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "klasseKunSmå",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 32,
      showName: "Linje",
      tooltipText: "",
      type: "Avanceret - Kun hård på 2",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "linjeKunSmå",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 33,
      showName: "Gruppe",
      tooltipText: "",
      type: "Avanceret - Kun hård på 2",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "gruppeKunSmå",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 34,
      showName: "Andenårselever",
      tooltipText: "",
      type: "Avanceret - Kun hård på 2",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "andenårsKunSmå",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 35,
      showName: "Sårbare elever",
      tooltipText: "",
      type: "Avanceret - Kun hård på 2",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "sårbarKunSmå",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 36,
      showName: "Brugerdefineret gruppe",
      tooltipText: "",
      type: "Avanceret - Kun hård på 2",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "brugerdefKunSmå",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 37,
      showName: "Kommune",
      tooltipText: "",
      type: "Avanceret - Kun hård på 2",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "kommuneKunSmå",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 38,
      showName: "Tidligere gangkammerater",
      tooltipText: "",
      type: "Avanceret - Kun hård på 2",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "tidlGangKammeraterKunSmå",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 39,
      showName: "Kan ikke bo med tidl. gangkammerater",
      tooltipText: "",
      type: "Avanceret - Kun seneste runde",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "tidlGangKammeraterKunEt",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 40,
      showName: "Kan ikke bo på tidl. gang",
      tooltipText: "",
      type: "Avanceret - Kun seneste runde",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "avoidPrevHallOnlyOne",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 41,
      showName: "Kan ikke bo på gang med tidl. roomie",
      tooltipText: "",
      type: "Avanceret - Kun seneste runde",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "undgåTidlRoomieKunEt",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 42,
      showName: "Opdeling af elever fra tidl. gange",
      tooltipText: "",
      type: "Avanceret - Kun seneste runde",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "splitPrevHallOnlyOne",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 43,
      showName: "Undgå store værelser",
      tooltipText: "",
      type: "Avanceret - Kun seneste runde",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "undgåStorKunEt",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 44,
      showName: "Undgå tidligere værelsesstørrelse",
      tooltipText: "",
      type: "Avanceret - Kun seneste runde",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "undgåTidlStrKunEt",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 45,
      showName: "Bruge vægtede gangparametre",
      tooltipText: "Vælg parameterstørrelse for hver enkelt blød parameter",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "check",
      value: false,
      disabled: false,
      serverName: "brugVægt",
      serverType: "advanced",
      defaultValue: false,
      defaultDisabled: false,
    },
    {
      id: 46,
      showName: "Klasse",
      tooltipText: "",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: true,
      serverName: "klasseVægt",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: true,
    },
    {
      id: 47,
      showName: "Linje",
      tooltipText: "",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: true,
      serverName: "linjeVægt",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: true,
    },
    {
      id: 48,
      showName: "Gruppe",
      tooltipText: "",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: true,
      serverName: "gruppeVægt",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: true,
    },
    {
      id: 49,
      showName: "Andenårselever",
      tooltipText: "",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: true,
      serverName: "andenårsVægt",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: true,
    },
    {
      id: 50,
      showName: "Sårbare elever",
      tooltipText: "",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: true,
      serverName: "sårbarVægt",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: true,
    },
    {
      id: 51,
      showName: "Landsdele",
      tooltipText: "",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: true,
      serverName: "landsdelVægt",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: true,
    },
    {
      id: 52,
      showName: "Brugerdefineret gruppe",
      tooltipText: "",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: true,
      serverName: "brugerdefVægt",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: true,
    },
    {
      id: 53,
      showName: "Opdeling af elever fra tidligere gange",
      tooltipText: "",
      type: "Avanceret - Vægtede gangparametre",
      formtype: "range",
      min: 1,
      value: 5,
      max: 20,
      step: 1,
      disabled: true,
      serverName: "tidlGangOpdelVægt",
      serverType: "advanced",
      defaultValue: 5,
      defaultDisabled: true,
    },
  ];
  if (type === "Værelse") return list.slice(0, 8);
  else if (type === "Gang") return list.slice(8, 17);
  else if (type === "Byt") return list.slice(17, 26);
  else if (type === "Adv") return list.slice(26, 53);
  else if (type === "AdvStandard") return list.slice(26, 29);
  else if (type === "AdvPostnummer") return list[30];
  else if (type === "AdvHårdeIndstillinger") return list.slice(30, 38);
  else if (type === "AdvSenesteVærelse") return list.slice(38, 44);
  else if (type === "AdvVægtedeParametre") return list.slice(44, 53);
  else if (type === "Alt") return list;
  else return list;
};
