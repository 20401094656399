import React from "react";
import Modal from "react-bootstrap/Modal";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";

function ItemModal(props) {
  let header = props?.props[0];
  let group = props?.props[1];
  let groupname = props?.props[2];
  let type = props?.props[3];
  if (type === "simple") {
    return (
      <Modal id="info-modal" show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>{header}</Modal.Header>
        <Modal.Body>
          <ListGroup>
            {(group !== null) & (group !== undefined) &&
              Object.keys(group).map((key) => {
                return (
                  <ListGroup.Item key={`list-${key}`}>
                    <Row>
                      <Col className="text-start" xs={7}>
                        {key}:
                      </Col>
                      <Col className="text-center" xs={5}>
                        <Badge>{group[key]}</Badge> {groupname}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Modal.Body>
      </Modal>
    );
  } else if (type === "adv") {
    return (
      <Modal id="info-modal" show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>{header}</Modal.Header>
        <Modal.Body>
          <ListGroup>
            {(group !== null) & (group !== undefined) &&
              Object.keys(group).map((key) => {
                return (
                  <ListGroup.Item key={`list-${key}`}>
                    <Row>
                      <Col className="text-start" xs={7}>
                        {group[key][1]}:
                      </Col>
                      <Col className="text-center" xs={5}>
                        {groupname}: <Badge>{group[key][0]}</Badge>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ItemModal;
