import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { useState } from "react";
import { useAuth } from "../../setup/app-context-manager/AuthContext";

function ForgottenPasswordForm(props) {
  const { resetPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  async function handleForgot(e) {
    e.preventDefault();

    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(props.mailRef.current.value);
      setMessage("Check din mail for yderligere instruktioner");
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setError("Ikke gyldig email");
      } else if (error.code === "auth/missing-email") {
        setError("Indtast email");
      } else {
        setError("Kunne ikke nulstille adgangskode");
        console.log(error.code);
      }
    }

    setLoading(false);
  }

  return (
    <Form onSubmit={handleForgot}>
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" ref={props.mailRef} placeholder="Email" />
      </Form.Group>
      <Button
        disabled={loading}
        variant="primary"
        type="submit"
        className="w-100"
      >
        Nulstil adgangskode
      </Button>
    </Form>
  );
}

export default ForgottenPasswordForm;
