import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import HelpModal from "./HelpModal";
import ModalAdvIndstillinger from "./ModalAdvIndstillinger";
import SettingsCard from "./SettingsCard";
import { useSettings } from "../../../../setup/app-context-manager/VrlsSettingsContext";
import Dropdown from "react-bootstrap/Dropdown";
import SkabelonDropdown from "./SkabelonDropdown";
import { useNavigate } from "react-router-dom";
import { useData } from "../../../../setup/app-context-manager/VrlsDataContext";

function VrlsIndstillinger() {
  let navigate = useNavigate();
  const { settings, handleChange, handleReset, handleResetAdvanced } =
    useSettings();
  const { setActiveStep } = useData();

  // Logik for visning af hjælpe-modal
  const [ShowModalHjælp, setShowModalHjælp] = useState(false);
  const handleHjælpShow = () => setShowModalHjælp(true);
  const handleHjælpClose = () => setShowModalHjælp(false);

  // Logik for visning af avancerede indstillinger modal
  const [ModalAdvanced, setModalAdvanced] = useState(false);

  const handleAdvancedClose = () => setModalAdvanced(false);
  const handleAdvancedShow = () => setModalAdvanced(true);

  function handleSubmit() {
    setActiveStep(5);
    navigate("../fordeling");
  }

  return (
    <>
      <Container style={{ minHeight: "82vh" }} fluid>
        <Container className="d-flex justify-content-center">
          <Container className="my-3 text-center">
            <Row>
              <Col
                xs={4}
                className="d-flex align-items-center justify-content-center"
              >
                <Button variant="danger" onClick={handleReset}>
                  Nulstil Indstillinger
                </Button>
              </Col>
              <Col
                xs={4}
                className="d-flex align-items-center justify-content-center"
              >
                <h1>Indstillinger</h1>
              </Col>
              <Col
                xs={4}
                className="d-flex align-items-center justify-content-center"
              >
                <Dropdown>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    Skabeloner
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <SkabelonDropdown />
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row className=" my-3">
              <Col xs={4} className="text-start">
                <SettingsCard
                  title="Værelsesindstillinger"
                  type="Værelse"
                  settings={settings}
                  handleChange={handleChange}
                />
              </Col>
              <Col xs={4} className="text-start">
                <SettingsCard
                  title="Gangindstillinger"
                  type="Gang"
                  settings={settings}
                  handleChange={handleChange}
                />
              </Col>
              <Col xs={4} className="text-start">
                <SettingsCard
                  title="Bytindstillinger"
                  type="Værelsesbyt"
                  settings={settings}
                  handleChange={handleChange}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={4}>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setActiveStep(3);
                    navigate("../godkend");
                  }}
                >
                  Tilbage
                </Button>
              </Col>
              <Col xs={4}>
                <Button variant="info" onClick={handleAdvancedShow}>
                  Avancerede Indstillinger
                </Button>
                <br />
                <Button
                  variant="outline-secondary"
                  onClick={handleHjælpShow}
                  className="mt-3"
                >
                  Hjælp
                </Button>
              </Col>
              <Col xs={4}>
                <Button onClick={handleSubmit}>Godkend</Button>
              </Col>
            </Row>
          </Container>
        </Container>
      </Container>
      <HelpModal show={ShowModalHjælp} handleClose={handleHjælpClose} />
      <ModalAdvIndstillinger
        showAdvanced={ModalAdvanced}
        handleAdvancedClose={handleAdvancedClose}
        handleResetAdvanced={handleResetAdvanced}
        settings={settings}
        handleChange={handleChange}
      />
    </>
  );
}

export default VrlsIndstillinger;
