import React from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

function ErrorModal(props) {
  let dataType = 0;
  if (props.data[3]) {
    if (props.data[3].length >= 1 && typeof props.data[3][0] === "string") {
      dataType = 1;
    } else if (
      props.data[3].length >= 1 &&
      typeof props.data[3][0] === "object" &&
      props.data[3][0].length === 2
    ) {
      dataType = 2;
    }
  }
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.data[1]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.data[0]}.
        <br />
        {props.data[2]}
        <br />
        {dataType === 1 && (
          <Table className="mt-3" striped bordered hover>
            {props.data[3].map((key, index) => {
              if (index === 0) {
                return (
                  <thead>
                    <tr key={`list-${key}`}>
                      <th>{key}</th>
                    </tr>
                  </thead>
                );
              } else {
                return (
                  <tbody>
                    <tr key={`list-${key}`}>
                      <td>{key}</td>
                    </tr>
                  </tbody>
                );
              }
            })}
          </Table>
        )}
        {dataType === 2 && (
          <Table className="mt-3" striped bordered hover>
            {props.data[3].map((key, index) => {
              if (index === 0) {
                return (
                  <thead>
                    <tr key={`list-${key[0]}`}>
                      <th>{key[0]}</th>
                      <th>{key[1]}</th>
                    </tr>
                  </thead>
                );
              } else {
                return (
                  <tbody>
                    <tr key={`list-${key[0]}`}>
                      <td>{key[0]}</td>
                      <td>{key[1]}</td>
                    </tr>
                  </tbody>
                );
              }
            })}
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ErrorModal;
