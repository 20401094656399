import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";
import { useSettings } from "../../../../setup/app-context-manager/VrlsSettingsContext";
import { useStorage } from "../../../../setup/app-context-manager/StorageContext";
import { useData } from "../../../../setup/app-context-manager/VrlsDataContext";

function VrlsHome() {
  const { resetSettings } = useSettings();
  const { downloadFile } = useStorage();
  const { clearData, setActiveStep } = useData();
  let navigate = useNavigate();
  function handleStart() {
    resetSettings();
    clearData();
    setActiveStep(1);
    navigate("../elevimport");
  }

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "82vh" }}
    >
      <Container className="mt-3 text-center">
        <Row>
          <Col></Col>
          <Col xl={5} xs={6}>
            <h1>VRLS</h1>
            <Container className="text-start">
              <p>
                Tak fordi I har valgt VRLS til at hjælpe jer med jeres
                værelsesfordeling!
              </p>
              <p>
                Vi er optagede af, at det skal være en god oplevelse, så derfor
                vil vi nu vejlede jer trygt og sikkert gennem de forskellige
                dele.
              </p>
              <p>
                Hvis i på noget tidspunkt mangler hjælp, er I selvfølgelig
                velkomne til at kontakte os.
              </p>
              <p>
                I kan finde skabeloner og eksempler ved at klikke på den ønskede
                fil til højre.
              </p>
              <p>
                Hvis I er klar til at komme i gang, så tryk på start-knappen
              </p>
            </Container>
            <Button onClick={handleStart}>Start</Button>
          </Col>
          <Col>
            <Card className="mt-5">
              <Card.Header>Hent filer</Card.Header>
              <ListGroup className="text-start" variant="flush">
                <ListGroup.Item
                  action
                  onClick={() => {
                    downloadFile("ElevSkabelon");
                  }}
                >
                  Skabelon: Elever
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {
                    downloadFile("VærelseSkabelon");
                  }}
                >
                  Skabelon: Værelser
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {
                    downloadFile("ElevEksempel");
                  }}
                >
                  Eksempel: Elever
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  onClick={() => {
                    downloadFile("VærelseEksempel");
                  }}
                >
                  Eksempel: Værelser
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default VrlsHome;
