import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

function HelpModal(props) {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Hjælp</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Husk, at hvis i har behov for hjælp er i altid velkomne til at
          kontakte Rasmus eller Kasper!
        </p>
        <p>I kan finde eksempelfiler og skabeloner på startsiden.</p>
        <Row>
          <Col>
            <strong>Kasper</strong>
            <p>
              Mail: kasper@vrls.dk
              <br />
              Tlf: +45 21 56 26 43
            </p>
          </Col>
          <Col>
            <strong>Rasmus</strong>
            <p>
              Mail: rasmus@vrls.dk <br />
              Tlf: +45 30 35 74 40
            </p>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default HelpModal;
