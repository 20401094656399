import React from "react";
import Card from "react-bootstrap/Card";
import TemplateList from "./TemplateList";
import Button from "react-bootstrap/Button";
import LinkContainer from "react-router-bootstrap/LinkContainer";
import { useSettings } from "../../../setup/app-context-manager/VrlsSettingsContext";

function TemplateCard() {
  const { resetSettings, resetCurrentTemplate } = useSettings();
  return (
    <Card>
      <Card.Body>
        <h3 className="text-center">Indstillingsskabeloner</h3>
        <TemplateList />
      </Card.Body>
      <LinkContainer to="../opret-skabelon">
        <Button
          onClick={() => {
            resetSettings();
            resetCurrentTemplate();
          }}
        >
          Opret ny skabelon
        </Button>
      </LinkContainer>
    </Card>
  );
}

export default TemplateCard;
