import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Import af sider
import ErrorPage from "../../pages/error/ErrorPage";

import DashboardHome from "../../pages/programmer/home/DashboardHome";
import Login from "../../pages/login/Login";
import SignUp from "../../pages/signup/SignUp";
import ForgottenPassword from "../../pages/forgottenpassword/ForgottenPassword";
import Profile from "../../pages/programmer/profile/Profile";
import UpdateProfile from "../../pages/programmer/updateprofile/UpdateProfile";
import ResetPassword from "../../pages/programmer/resetpassword/ResetPassword";

// Import af VRLS-sider
import VrlsHome from "../../pages/programmer/vrls/home/VrlsHome";
import VrlsIndstillinger from "../../pages/programmer/vrls/indstillinger/VrlsIndstillinger";
import VrlsElevImport from "../../pages/programmer/vrls/elevimport/VrlsElevImport";
import VrlsVærelseImport from "../../pages/programmer/vrls/værelseimport/VrlsVærelseImport";
import VrlsGodkend from "../../pages/programmer/vrls/godkend/VrlsGodkend";
import VrlsFordeling from "../../pages/programmer/vrls/fordeling/VrlsFordeling";

// Import af andre Routes
import { PrivateRoute } from "./PrivateRoute";
import { VrlsRoute } from "./VrlsRoute";
import CreateTemplate from "../../pages/programmer/createtemplate/CreateTemplate";

function RouterIndex() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard/home" />} />
      <Route path="login" element={<Login />} />
      <Route path="opret" element={<SignUp />} />
      <Route path="glemt-kode" element={<ForgottenPassword />} />
      <Route path="dashboard" element={<PrivateRoute />}>
        <Route path="profil" element={<Profile />} />
        <Route path="opdater-profil" element={<UpdateProfile />} />
        <Route path="opdater-password" element={<ResetPassword />} />
        <Route path="home" element={<DashboardHome />} />
        <Route path="opret-skabelon" element={<CreateTemplate />} />
        <Route path="vrls" element={<VrlsRoute />}>
          <Route path="home" element={<VrlsHome />} />
          <Route path="elevimport" element={<VrlsElevImport />} />
          <Route path="vaerelseimport" element={<VrlsVærelseImport />} />
          <Route path="godkend" element={<VrlsGodkend />} />
          <Route path="indstillinger" element={<VrlsIndstillinger />} />
          <Route path="fordeling" element={<VrlsFordeling />} />
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default RouterIndex;
