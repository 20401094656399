import React, { createContext, useState, useContext } from "react";
import { IndstillingerList } from "../../assets/Indstillinger";
import VrlsSettingsInverter from "../../handlers/VrlsSettingsInverter";

const VrlsSettingsContext = createContext(null);

export function useSettings() {
  return useContext(VrlsSettingsContext);
}

export const VrlsSettingsProvider = ({ children }) => {
  // Logik for Indstillinger
  const defaultSettings = IndstillingerList("Alt");
  const [settings, setSettings] = useState(defaultSettings);
  const [currentTemplate, setCurrentTemplate] = useState([]);

  function handleChange(event) {
    const id = event.target.id;
    const tempValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (id === "45") {
      const idWeightedArray = [46, 47, 48, 49, 50, 51, 52, 53];
      const idGeneralWeight = 29;
      const newState = settings.map((obj) => {
        if (obj.id === Number(id)) {
          return { ...obj, value: tempValue };
        } else if (idWeightedArray.includes(obj.id)) {
          let tempValue = obj.defaultValue;
          let tempDisabled = !obj.disabled;
          return { ...obj, value: tempValue, disabled: tempDisabled };
        } else if (obj.id === idGeneralWeight) {
          let tempValue = obj.defaultValue;
          let tempDisabled = !obj.disabled;
          return { ...obj, value: tempValue, disabled: tempDisabled };
        }
        return obj;
      });
      setSettings(newState);
    } else {
      const newState = settings.map((obj) => {
        if (obj.id === Number(id)) {
          return { ...obj, value: tempValue };
        }
        return obj;
      });
      setSettings(newState);
    }
  }

  function handleResetAdvanced() {
    const newSettings = settings.map((obj) => {
      if (obj.serverType === "advanced") {
        let defaultValue = obj.defaultValue;
        let defaultDisabled = obj.defaultDisabled;
        return { ...obj, value: defaultValue, disabled: defaultDisabled };
      }
      return obj;
    });
    setSettings(newSettings);
  }

  function handleReset(event) {
    event.preventDefault();
    setSettings(defaultSettings);
  }

  function resetSettings() {
    setSettings(defaultSettings);
  }

  function applyTemplate(template) {
    const tempSettings = VrlsSettingsInverter(template.settings);
    setSettings(tempSettings);
    setCurrentTemplate([template.name, template.id]);
  }

  function resetCurrentTemplate() {
    setCurrentTemplate([]);
  }

  const value = {
    settings,
    currentTemplate,
    handleChange,
    handleReset,
    handleResetAdvanced,
    resetSettings,
    applyTemplate,
    resetCurrentTemplate,
  };

  return (
    <VrlsSettingsContext.Provider value={value}>
      {children}
    </VrlsSettingsContext.Provider>
  );
};
